.md-not-found-page {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-right: 1rem;
    max-height: 24rem;

    @media screen and (max-width: 575px) {
      max-height: 18rem;
    }
  }

  .not-found-content {
    display: block;
    text-align: center;
    margin-right: 6rem;
    font-weight: 700;
    color: #000000;

    @media screen and (max-width: 575px) {
      margin-right: 1rem;
    }

    h2 {
      font-size: 8rem;
      line-height: 1;
    }

    p {
      width: 15rem;
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

    a {
      font-weight: 700;
    }
  }
}
