//Home Inspection Modal Styles (Desktop/Mobile)
.md-inspection-modal {

    .modal-dialog {
        justify-content: center;
        max-width: 800px !important;
        font-size: 14px;
        margin-left: auto;
        margin-right: auto;

        .modal-content {
            border: 0;
            border-radius: 0;
        }

        .modal-body {
            padding: 0;
        }
    }

    .react-pdf__Page__canvas {
        width: 100% !important;
        height: 100% !important;
    }
}