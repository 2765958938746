//Desktop Repair Modal Styles
.create-project-modal {
    
    @keyframes slide-in {
        from {
            top: -50px;
        }
        to {
            top: 0;
        }
    }

    .modal-dialog {
        justify-content: center;
        max-width: 600px;
        font-size: 14px;

        .modal-content {
            border: 0;
            border-radius: 0;

            .modal-header {
                background-color: #eee;
                align-items: center;
                padding: 15px 28px;

                @media screen and (max-width: 575px) {
                    padding: 15px 20px;
                }

                .md-modal-title {
                    font-family: "Mark", sans-serif;
                    font-weight: 700;
                    font-size: 23px;

                    @media screen and (max-width: 575px) {
                        font-size: 16px;
                    }

                    .fa-print {
                        margin-left: 10px;
                    }
                }
            }

            .modal-body {
                max-height: 680px;
                overflow-y: scroll;
                padding: 30px;
                position: relative;

                @media screen and (max-width: 575px) {
                    max-height: 100%;
                }

                .md-error-message {
                    animation: slide-in 1s;
                    background: #c42527;
                    color: #ffffff;
                    display: flex;
                    justify-content: space-between;
                    margin-left: -30px;
                    padding: 10px 30px;
                    position: absolute;
                    top: 0;
                    width: 100%;

                    p {
                        margin: 0;
                    }

                    a {
                        color: #ffffff;
                    }
                }

                .md-modal-subheader {
                    font-size: 16px;
                    letter-spacing: normal;
                    margin: 0;
                    max-width: 430px;
                    padding: 0px 0px 15px 0px;

                    @media screen and (max-width: 575px) {
                        max-width: 180px;
                    }
                }

                .create-project-form {
                    display: flex;
                    flex-direction: column;
                    
                    label {
                        align-self: flex-start;
                        color: #009FD9;
                        font-family: "Mark", sans-serif;
                        font-weight: 700;
                        margin: 10px 0 0;

                        &:first-child {
                            margin: 0;
                        }
                    }

                    input {
                        border: 1px solid #dddddd;
                        border-radius: 4px;
                        margin: 0;
                        padding: 10px;
                    }

                    select {
                        border: 1px solid #dddddd;
                        border-radius: 4px;
                        padding: 11px;
                        width: 100%;

                        &.default {
                            color: #777777;
                        }
                    }

                    textarea {
                        border: 1px solid #dddddd;
                        border-radius: 4px;
                        min-height: 100px;
                        padding: 8px;
                    }

                    .invalid {
                        border: 1px solid #c42527;
                        color: #c42527
                    }

                    .quote-details {
                        p {
                            margin: 0;
                        }
                    }

                    .request-wrapper {
                        display: flex;
                        flex-direction: column;
                        margin-top: 10px;
    
                        .request-header {
                            background-color: #FAFAFA;
                            display: flex;
                            align-items: center;
                            margin: 10px 0px 10px;
                            padding: 15px 15px;
    
                            .md-modal-subheader {
                                color: #009FD9;
                                padding: 0;
                            }
                        }
    
                        .request-actions {
                            display: flex;
                            flex-direction: column;

                            .request-value-wrapper {
                                position: relative;
                                display: flex;
                                align-items: center;
        
                                input {
                                    padding: 9px 9px 8px 15px;
                                    width: 100%;
                                }
                    
                                p {
                                    margin: 0;
                                    position: absolute;
                                    top: 15px;
                                    left: 5px;
                                }
                            }
                        }
                    }
                }

                hr {
                    width: 90%;
                    margin-top: 20px;
                    margin-bottom: 20px;
                }

                .md-modal-actions {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    margin-top: 10px;

                    @media (max-width: 575px) {
                        flex-direction: column;
                    }

                    .btn {
                        border-radius: 3px;
                        font-size: 14px;
                        font-weight: 700;
                        padding: 8px 15px;
                        width: calc(50% - 10px);
                        margin: 5px;

                        @media (max-width: 575px) {
                            width: 100%;
                        }

                        &.disabled {
                            background: #979797;
                            border: 1px solid #979797;
                            opacity: 0.5;
                            cursor: default;
                        }
                    }

                    .cancel-btn {
                        border: 1px solid #2F3033;
                        border-radius: 2px;
                        color: #2F3033 !important;

                        &:hover {
                            color: #2F3033 !important;
                        }
                    }

                    .submit-btn {
                        background-color: #009FD9;
                        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
                        color: #ffffff;

                        @media (max-width: 575px) {
                            margin-bottom: 30px;
                        }
                    }
                }

            }
        }
    }
}