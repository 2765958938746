.md-loading-img-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0 !important;

    .md-spinner-container {
        padding: 0 !important;

        .md-loading-spinner {
            height: 50px !important;
            width: 50px !important;
        }
    }
}