//Desktop Repair Modal Styles
.request-list-sharing-modal {
    .modal-dialog {
        justify-content: center;
        max-width: 600px;
        font-size: 14px;

        .modal-content {
            border: 0;
            border-radius: 0;

            .modal-header {
                background-color: #eee;
                align-items: center;
                padding: 15px 28px;

                @media screen and (max-width: 575px) {
                    padding: 15px 20px;
                }

                .md-modal-title {
                    font-family: "Mark", sans-serif;
                    font-weight: 700;
                    font-size: 23px;

                    @media screen and (max-width: 575px) {
                        font-size: 16px;
                    }

                    .fa-print {
                        margin-left: 10px;
                    }
                }
            }

            .modal-body {
                max-height: 680px;
                overflow-y: scroll;
                padding: 30px;

                @media screen and (max-width: 575px) {
                    max-height: 100%;
                }
                
                .copy-message {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    margin-left: -30px;
                    padding: 10px 28px;
                    background: darkgreen;
                    color: #ffffff;
                    -webkit-animation: slide-in-top 0.3s;
                    animation: slide-in-top 0.3s;

                    p {
                        margin: 0;

                        &.close-button:hover {
                            cursor: pointer;
                        }
                    }
                }

                .url-input-wrapper {
                    padding: 0 5px;

                    input {
                        padding: 5px;
                        width: 100%;
                    }
                }

                .md-modal-actions {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    margin-top: 10px;

                    @media (max-width: 575px) {
                        flex-direction: column;
                    }

                    .btn {
                        border-radius: 3px;
                        font-size: 14px;
                        font-weight: 700;
                        padding: 8px 15px;
                        width: calc(50% - 10px);
                        margin: 5px;

                        @media (max-width: 575px) {
                            width: 100%;
                        }
                    }

                    .cancel-btn {
                        border: 1px solid #2F3033;
                        border-radius: 2px;
                        color: #2F3033 !important
                    }

                    .submit-btn {
                        background-color: #009FD9;
                        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
                        color: #ffffff;

                        @media (max-width: 575px) {
                            margin-bottom: 30px;
                        }
                    }
                }

            }
        }
    }
}