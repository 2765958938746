a {
  color: #63666a;
}

.btn-uiyellow {
  color: #ffffff;
}

.md-btn-secondary {
  float: right;
  background: transparent;
  color: #fff;
  font-size: 12px;
  letter-spacing: normal;
  text-transform: uppercase;
  margin-top: 10px;
  margin-right: 10px;
  border: 1px solid #333;
}

.md-btn-primary {
  float: right;
  background-color: #009FD9;
  color: #fff;
  font-size: 12px;
  letter-spacing: normal;
  text-transform: uppercase;
  margin-top: 10px;
  min-width: 50px;
  border-radius: 0;
  height: 50px;
}

.md-list {
  display: flex;
  align-items: center;
  width: 350px;
  height: 70px;
  position: sticky;
  bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 5px;
  font-size: 16px;
  box-shadow: 0 -4px 20px 0 rgba(0, 0, 0, 0.15);

  @media screen and (max-width: 414px) {
    width: 300px;
  }

  p {
    text-align: center;
    margin-top: 15px;
  }

  .btn-primary {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #009FD9;
    color: #fff;
    font-size: 12px;
    letter-spacing: normal;
    text-transform: uppercase;
    border: 1px solid #009FD9;
    height: 38px;
  }
}

.btn-tertiary {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #009FD9;
  color: #fff;
  font-size: 12px;
  letter-spacing: normal;
  border: 1px solid #009FD9;
  height: 38px;
}

.md-actions-container {
  margin: 20px 0;

  @media print {
    display: none;
  }

  .md-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    a {
      color: #ffffff;
      &:hover {
          color: #ffffff !important;
        }
    }

    .btn {
      font-family: "Mark";
      font-weight: 700;
      font-size: 14px;
      letter-spacing: normal;
      min-width: 150px;
      color: #ffffff;

      i {
        margin-left: 5px;
      }
    }
  }
}

.md-domoreport-page {
  padding: 0;

  input:disabled {
    background: #f8f8f8;
    cursor: no-drop;
  }

  select:disabled {
    background: #f8f8f8;
    cursor: no-drop;
  }

  textarea:disabled {
    opacity: 0.5;
    cursor: no-drop;
  }

  .md-domoreport-body {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 15px 0 0;
    // overflow: hidden;
    -webkit-print-color-adjust: exact;
    font-size: 16px;
    margin: 15px auto;
    max-width: calc(100% - 30px);

    @media screen and (max-width: 991px) {
      padding: 0 1rem;
      box-shadow: none;
      margin-bottom: 30px;
      max-width: 100%;
    }

    @media screen and (max-width: 575px) {
      padding: 0;
    }

    .menu-link {
      padding-left: 15px;

      @media print {
        display: none !important;
      }
    }

    .report-section-header {
      font-family: "Mark", sans-serif;
      font-size: 22px;
      font-weight: 700;
      letter-spacing: normal;
      color: #009FD9;
      margin: 15px;

      i {
        margin-left: 10px;
      }
    }

    .overview-wrapper {
      display: flex;
      justify-content: center;
      flex-flow: row wrap;
      align-items: flex-start;
      background: #FAFAFA;
      padding: 20px;

      @media print {
        order: 1;
      }

      @media screen and (max-width: 991px) {
        margin: 0;
        border-radius: 5px;
      }

      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
        padding: 20px;
        padding-top: 30px;
        border-radius: 5px;
      }

      @media screen and (max-width: 575px) {
        padding-bottom: 0px;
      }

      .section {
        height: 100%;
        display: flex;
        justify-content: center;
        border-right: solid 1px #cccccc;
        overflow-wrap: break-word;
        min-height: 100px;

        @media screen and (max-width: 767px) {
          justify-content: left;
          border: 0;
          margin-bottom: 30px;
        }

        @media print {
          -ms-flex: 0 0 25% !important;
          flex: 0 0 25% !important;
          max-width: 25% !important;
        }

        &:last-child {
          border: 0;
        }

        div {
          max-width: 100%;
        }

        .label {
          font-family: "Mark";
          font-size: 12px;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #4a4a4a;
          margin: 0;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          @media screen and (max-width: 767px) {
            font-weight: bold;
            letter-spacing: normal;
            color: #9b9b9b !important;
            text-transform: uppercase;
            margin-bottom: 5px !important;
          }
        }

        .data {
          font-family: "Mark";
          font-size: 16px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #009FD9;
          margin-bottom: 8px;

          @media screen and (max-width: 767px) {
            font-size: 16px !important;
            font-weight: normal !important;
            color: #272525 !important;
          }
        }
      }
    }

    .map-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding: 15px;

      &.d-none.d-md-flex {
        @media print {
          display: flex !important;
        }
      }

      &.d-flex.d-md-none {
        @media print {
          display: none !important;
        }
      }

      @media screen and (max-width: 767px) {
        padding: 0 !important;
        margin-bottom: 15px;
      }

      .map {
        @media screen and (min-width: 767px) {
          padding-right: 0;
        }

        @media screen and (max-width: 767px) {
          height: 300px !important;
          border-radius: 5px !important;
        }

        @media screen and (max-width: 575px) {
          height: 250px !important;
          border-radius: 5px !important;
        }

        @media print {
          -ms-flex: 0 0 50% !important;
          flex: 0 0 50% !important;
          max-width: 50% !important;
        }
      }

      div.image {
        height: 100%;
        width: 100%;
        border-radius: 5px;
        min-height: 340px;
        padding: 0;

        @media screen and (max-width: 767px) {
          padding: 15px;
          min-height: 300px;
        }

        @media screen and (max-width: 575px) {
          min-height: 250px;
        }

        @media print {
          -ms-flex: 0 0 50% !important;
          flex: 0 0 50% !important;
          max-width: 50% !important;
        }
      }

      img {
        max-height: 340px;
          object-fit: cover;
      }

      .property-street-image {
        width: 100%;
        height: 340px;
        background-color: #3a84ff;
        background-size: cover;
      }

      .img {
        width: 100%;
        height: 340px;
        background-color: #3a84ff;

        @media print {
          width: 1000px !important;
        }
      }

      .map-marker {
        box-shadow: 0px 0px 4px 4px rgba(58, 132, 255, 0.4);
        background-color: #3a84ff;
        display: inline-flex;
        transform: translate(-50%, -50%);
        height: 16px;
        width: 16px;
        border-radius: 50%;
        z-index: 1000;
      }

      .map-marker-text {
        position: absolute;
        top: -50px;
        background: white;
        width: 150px;
        height: 50px;
        padding: 5px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

        p {
          margin: 0;
          padding-left: 15px;
          text-indent: -5px;
        }
      }
    }

    .summary-wrapper {
      .summary-cards {
        padding: 0 15px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-flow: row wrap;

        @media screen and (max-width: 1200px) {
          justify-content: flex-start;
        }

        .card-inactive {
          opacity: 0.5;
          transform: scale(0.98);
          box-shadow: 0px 0px 15px rgba(18, 61, 186, 0.22);
        }

        .card-inactive:hover {
          transform: scale(1);
          opacity: 1;
        }

        .card {
          border: 0;
          width: calc(20% - 10px);
          box-shadow: 0px 0px 15px rgba(18, 61, 186, 0.22);
          min-height: 190px;
          background: #fff;
          margin: 0;
          cursor: pointer;
          transition: 0.4s;

          @media screen and (max-width: 1200px) {
            width: calc(33% - 16px);
            margin: 5px;
          }

          @media screen and (max-width: 767px) {
            width: calc(33% - 16px);
            margin: 5px;
          }

          @media screen and (max-width: 575px) {
            width: calc(50% - 16px);
          }

          @media screen and (max-width: 359px) {
            font-size: 14px;
          }

          .heading {
            padding: 10px;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // text-transform: uppercase;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            font-family: "Mark", sans-serif;
            font-size: 15px;
            font-weight: 700;
            letter-spacing: normal;
            white-space: nowrap;
            text-overflow: ellipsis;

            @media screen and (max-width: 575px) {
              font-size: 11px;
              letter-spacing: normal;
            }

            i {
              margin-left: 10px;
              color: #fff;
            }

            i:hover {
              text-decoration: none;
              color: #eee;
            }

            .print-dropdown {
              background: #000;
              color: white;
              font-size: 12px;
              text-align: right;
              position: absolute;
              top: 35px;
              right: 0;
              width: 95px;
              height: 35px;
              padding: 9px;
              box-shadow: 5px 5px 30px 0 rgba(0, 55, 50, 0.25);

              a {
                color: white !important;
              }

              i {
                margin: 0;
              }
            }

            .print-dropdown::after {
              content: "";
              position: absolute;
              bottom: 100%;
              left: 80%;
              margin-left: -5px;
              border-width: 5px;
              border-style: solid;
              border-color: transparent transparent #000 transparent;
            }
          }

          .number-repairs-wrapper {
            padding: 15px 20px;

            .label {
              font-family: "Mark", sans-serif;
              font-size: 12px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #4a4a4a;
            }

            .number {
              font-family: "Mark", sans-serif;
              font-size: 30px;
              font-weight: 600;
              letter-spacing: normal;
              color: #2F3033;
              padding: 5px 0;

              @media screen and (max-width: 575px) {
                font-size: 20px;
              }
            }
          }
        }
      }
    }

    .my-list-summary {
      background: #ffffffdd;
      border-radius: 3px;
      box-shadow: 3px 0px 20px 0 rgba(0, 55, 130, 0.15);
      padding: 10px 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: row;
      margin: 30px 15px 60px 15px;
      z-index: 1000;

      @media (min-width: 766px) {
        backdrop-filter: blur(7px);
        position: sticky;
        top: 0px;
      }

      @media (max-width: 991px) {
        margin: 10px 15px;
      }

      @media (max-width: 767px) {
        box-shadow: none;
        margin: 0 15px;
        padding-top: 0;
      }

      .list-item {
        display: flex;
        align-items: center;

        @media (max-width: 767px) {
          align-items: center;
          display: flex;
          justify-content: center;
          margin: 5px 0;
        }

        .list-img-wrapper {
          padding: 15px;
          margin-right: 10px;
          border-radius: 50%;
          background: #e0e5ef;
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 25px;
          }
        }

        p {
          display: flex;
          flex-wrap: wrap;
          font-size: 14px;
          margin: 0;

          @media (max-width: 767px) {
            max-width: 150px;
          }

          span {
            font-weight: 700;
            width: 100%;
          }
        }

        &.actions {
          display: flex;
          flex-direction: column;

          @media (max-width: 575px) {
            margin: 15px 0;
          }

          .btn {
            border-radius: 2px;
            font-size: 14px;
            font-weight: 700;
            margin: 2px 0;
            max-width: 250px;
            width: 100%;
          }

          .add-new-btn {
            background: #ffffff;
            border: 1px solid #63666a;
            color: #63666a;

            &:hover {
              border: 1px solid #2F3033;
              color: #2F3033 !important;
            }
          }

          .preview-btn {
            background: #009FD9;
            border: 1px solid #009FD9;
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
            color: #ffffff;
            padding: 5px;
            width: 100%;

            &.disabled {
              background: #979797;
              border: 1px solid #979797;
              opacity: 0.5;
              cursor: default;
            }
          }
        }
      }
    }

    .my-list-notes {
      textarea {
        width: 100%;
        padding: 15px;
        border: 1px solid #dddddd;
        border-radius: 5px;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
      }

      .md-example-notes-wrapper {
        display: flex;
        padding: 0 15px;
        position: relative;

        p {
          color: #2174a5;
          cursor: pointer;
          font-size: 14px;
          font-weight: 600;
          margin: 0;
          text-decoration: none dashed;
        }

        .md-example-notes {
          background: #ffffff;
          border: 1px solid #eeeeee;
          border-radius: 3px;
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
          display: inline;
          left: 15px;
          max-width: 350px;
          opacity: 1;
          padding: 10px;
          position: absolute;
          top: 20px;
          transition: 0.3s;
          z-index: 100;

          &.hidden-notes {
            display: none;
            opacity: 0;
          }

          p {
            color: #2F3033;
            cursor: auto;
            font-weight: normal;
            text-decoration: none;
          }
        }
      }
    }

    .notes {
      border-radius: 5px;
      box-shadow: 3px 0px 20px 0 rgba(0, 55, 130, 0.1);
      background-color: #ffffff;
      padding: 30px;
      margin: 30px 15px;
      position: relative;
      height: 79px;

      -webkit-transition: 0.5s;
      -moz-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;

      @media screen and (max-width: 767px) {
        margin: 30px 0px 30px 0px;
      }

      &.show {
        height: 100%;
        overflow: hidden;

        @media screen and (max-width: 767px) {
          height: 100%;
        }

        @media screen and (max-width: 350px) {
          height: 100%;
        }
      }

      h2 {
        font-family: "Mark", sans-serif;
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #009FD9;
        padding-bottom: 20px;
        margin-bottom: 0;
        // text-transform: uppercase;
      }

      p.desc {
        width: 100%;
        font-family: "Mark", sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;
        border-top: solid 1px #979797;
        padding-top: 20px;
        white-space: pre-line;
        color: #2F3033;
        // border-top: solid 1px #979797;
        padding-top: 10px;
        white-space: pre-line;

        li {
          margin-top: calc(0.625rem * calc(1 - 1));
          margin-bottom: calc(0.625rem * 1);
        }
      }

      .notes-footer-wrapper {
        width: 100%;

        .footer-content {
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          justify-content: flex-start;

          .img {
            margin-right: 18px;
          }
        }

        .img {
          background: #d8d8d8;
          height: 55px;
          width: 55px;
          border-radius: 50%;
          margin-right: 18px;
        }

        .name-wrapper {
          .name {
            display: flex;
            align-items: center;
            font-weight: bold;
            color: #4a4a4a;
            font-size: 16px;
            letter-spacing: normal;

            a {
              padding: 5px;
              padding-bottom: 7px;

              img:hover {
                cursor: pointer;
              }
            }
          }

          .title {
            font-size: 13px;
            color: #9b9b9b;
          }
        }
      }
    }

    .contest-action {
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        padding: 15px;
        margin-top: 40px;
        font-weight: 700;
        border-radius: 3px;
        width: 280px;

        // -webkit-clip-path: polygon(90% 0, 100% 33%, 100% 100%, 0 100%, 0 0);
        // clip-path: polygon(90% 0, 100% 33%, 100% 100%, 0 100%, 0 0);
      }
    }

    //Desktop Table Styles
    .repair-detail-wrapper {
      margin-top: 30px;
      overflow: hidden;

      .repair-table-actions {
        margin-bottom: 15px;
        padding: 0 15px;
        font-size: 14px;
        font-weight: 700;
        font-family: "Mark", sans-serif;

        a {
          margin-right: 10px;

          i {
            margin-right: 5px;
          }
        }
      }

      h2 {
        font-family: "Mark", sans-serif;
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #009FD9;
      }

      .md-priority-tag {
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        margin-left: auto;
        margin-right: auto;
        border: 0;
        width: 100%;
        padding: 3px;
        clip-path: polygon(100% 0, 100% 100%, 0% 100%, 5% 50%, 0 0);
        font-size: 12px;
      }

      .repairs-details-header {
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        margin-bottom: 20px;

        h2 {
          margin: 0;

          a {
            margin-left: 10px;
          }
        }

        .dropdown-toggle {
          background-color: transparent;
          color: #52575a;
          border: 0;
          padding: 0;
        }

        .dropdown-toggle:focus {
          box-shadow: none;
          color: #52575a;
        }

        .dropdown-toggle:active {
          box-shadow: none;
          background: transparent;
          color: #52575a;
        }

        .dropdown-item:active {
          color: black;
          text-decoration: none;
          background-color: transparent;
        }
      }

      .details-table {
        table-layout: fixed;
        width: 100%;

        @media screen and (max-width: 1305px) {
          display: table-cell;
        }

        thead {
          tr {
            td {
              font-size: 13px;

              a {
                color: #009FD9;
              }
            }
          }
        }

        td {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
          font-family: "Mark", sans-serif;
          font-size: 14px;
          font-weight: 400;
          color: #63666a;
          padding: 10px;
          border-bottom: 1px solid #d8d8d8;
        }

        td:nth-child(1) {
          width: 25%;
        }

        td:nth-child(2) {
          width: 10%;
        }

        td:nth-child(3) {
          width: 11%;
        }

        td:nth-child(4) {
          width: 7%;
        }

        td:nth-child(5) {
          width: 9%;
        }

        .table-row {
          background-color: #f6f7f7;
          box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.06);
          transition: 0.4s;

          span {
            transition: 0.4s;
          }

          .md-view-details {
            transition: 0.4s;
            clip-path: polygon(100% 0, 100% 100%, 0% 100%, 0% 50%, 0 0);
            transition: 0.3s;
          }

          .md-view-details:hover {
            text-decoration: none;
            cursor: pointer;
            // transform: scale(1.04)
          }
        }

        .table-row:hover {
          background-color: #e5e6e6;
        }

        .heading {
          box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.06);

          td {
            color: #fff !important;
            font-family: "Mark", sans-serif;
            font-size: 16px;
            font-weight: 700;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
          }
        }
      }
    }

    //Mobile Table Styles
    .repair-card-wrapper {
      width: 100%;

      .md-priority-tag {
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        margin-left: auto;
        margin-right: auto;
        border: 0;
        width: 100%;
        padding: 3px;
        clip-path: polygon(100% 0, 100% 100%, 0% 100%, 5% 50%, 0 0);
        font-size: 12px;
      }

      .heading-mobile {
        position: sticky;
        top: 0;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        font-weight: bold;
        color: white;
        margin-bottom: 10px;

        p {
          margin: 0;
        }
      }

      .repair-card {
        box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.06);
        background-color: #f6f7f7;
        padding: 20px 10px;
        margin-bottom: 20px;
        margin-top: 20px;
        font-size: 14px;

        @media screen and (max-width: 575px) {
          .col-1 {
            padding: 10px;
          }
        }

        .md-card-heading {
          text-transform: uppercase;
          font-size: 14px;
        }

        .repair-name {
          display: flex;
          align-items: center;
          padding: 0;

          @media screen and (max-width: 767px) {
            padding-left: 15px;
          }

          p {
            margin: 0;
            font-weight: bold;
          }
        }

        .repair-priority {
          position: absolute;
          right: 0;
          margin-top: 15px;
          display: flex;
          justify-content: flex-end;
          min-width: 115px;
        }

        .repair-details {
          a {
            color: #009FD9;
            font-weight: 700;
            letter-spacing: normal;
          }
        }
      }
    }

    .md-disclaimer {
      color: #777;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 20px;
      background-color: #FAFAFA;

      .satisfaction-text {
        font-size: 14px;
        text-align: center;
        margin-bottom: 20px;
      }

      p {
        margin: 0;
      }
    }

    .my-list-footer {
      align-items: center;
      background: #ffffff;
      bottom: 0;
      display: flex;
      justify-content: center;
      opacity: 0.8;
      padding: 10px 20px;
      position: sticky;
      transition: 0.5s;
      z-index: 1000;

      @media (max-width: 991px) {
        flex-direction: column-reverse;
      }

      &:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
        opacity: 1;
      }
    }
  }

  .md-cta-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    padding-top: 15px;

    .btn {
      border-radius: 0;
      font-weight: 700;
      letter-spacing: normal;
      font-size: 14px;
      padding: 15px;

      -webkit-clip-path: polygon(88% 0, 100% 35%, 100% 100%, 0 100%, 0 0);
      clip-path: polygon(88% 0, 100%, 35%, 100% 100%, 0 100%, 0 0);
    }
  }
}

.md-clickable {
  cursor: pointer;
}