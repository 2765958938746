.md-root-footer-container {
  position: relative;
  bottom: 0;
  width: 100%;

  @media print {
    display: none;
  }

  .md-root-footer {
    padding: 60px 90px;
    padding-right: 0;
    padding-bottom: 30px;
    background: #ececec;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    flex-flow: row wrap;

    @media screen and (max-width: 1200px) {
      padding: 30px 15px;
    }

    @media screen and (max-width: 767px) {
      padding: 30px 60px;
    }

    @media screen and (max-width: 575px) {
      padding: 30px 10px;
    }

    .md-logo-container {
      @media screen and (max-width: 1200px) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 20px;
      }

      @media screen and (max-width: 575px) {
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0;
        padding-left: 15px;
      }

      .logo {
        img {
          max-width: 220px;

          @media screen and (max-width: 991px) {
            margin-bottom: 10px;
          }
        }
      }

      span {
        font-family: "Mark", sans-serif;
        font-size: 12px !important;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 2;
        letter-spacing: normal;
        color: #4a4a4a;

        @media screen and (max-width: 991px) {
          text-align: center;
        }

        @media screen and (max-width: 575px) {
          padding: 30px 10px;
        }

        .md-logo-container {
          @media screen and (max-width: 1200px) {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 20px;
          }

          @media screen and (max-width: 575px) {
            align-items: flex-start;
            justify-content: flex-start;
            padding: 0;
            padding-left: 15px;
          }

          .logo {
            img {
              max-width: 220px;

              @media screen and (max-width: 991px) {
                margin-bottom: 10px;
              }
            }
          }

          span {
            font-family: "Mark", sans-serif;
            font-size: 12px !important;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 2;
            letter-spacing: normal;
            color: #4a4a4a;

            @media screen and (max-width: 991px) {
              text-align: center;
            }
          }
        }

        h4 {
          &.heading {
            font-family: "Mark", sans-serif;
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.67;
            letter-spacing: normal;
            color: #000000;
            margin: 0;
          }
        }

        ul.link-list {
          list-style: none;
          padding: 0;
          margin: 0 0 20px;

          li {
            margin-bottom: 5px;

            a {
              font-size: 14px;
              letter-spacing: normal;
              color: #4a4a4a;
            }
          }
        }
      }
    }

    h4 {
      &.heading {
        font-family: "Mark", sans-serif;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.67;
        letter-spacing: normal;
        color: #000000;
        margin: 0;
      }
    }

    ul.link-list {
      list-style: none;
      padding: 0;
      margin: 0 0 20px;

      li {
        margin-bottom: 5px;

        a {
          font-size: 14px;
          letter-spacing: normal;
          color: #4a4a4a;
        }
      }
    }
  }
}
