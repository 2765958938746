.md-sharing-modal {

    .modal-dialog {
        max-width: 750px !important;

        @media screen and (max-width: 575px) {
            margin: 0;
            border-radius: 0;
        }

        .modal-content {
            border: 0;
            font-size: 16px;

            @media screen and (max-width: 575px) {
                border-radius: 0 !important; 
                font-size: 14px;;
            }

            .modal-header {
                padding: 10px;
                display: flex;
                align-items: center;

                .modal-title {
                    padding: 0 20px;
                    font-size: 30px;

                    @media screen and (max-width: 575px) {
                        font-size: 25px;
                        padding: 0 5px;
                    }
                }
            }

            .modal-body {
                padding: 30px;
                padding-top: 10px;

                @media screen and (max-width: 575px) {
                    padding: 15px;
                }

                .md-spinner-container {
                    height: 100%;
                    width: 100%;
                    padding: 40px;
                }

                label {
                    width: 100%;
                    font-size: 16px;
                }
            
                select {
                    width: 100%;
                    height: 40px;
                    border: 1px solid #ccc;
                    color: #333;
                    background: white;
                }  

                select:disabled {
                    background-color: #eee;
                    color: #000;
                    opacity: .5;
                }
                
                .modal-address {
                    font-weight: bold;
                    color: #009FD9;
                }

                .dropdown-label {
                    @media screen and (max-width: 767px) {
                        margin-top: 10px;
                    }
                }
                
                .md-manage-shares-wrapper {
                    padding: 0 10px;
                    font-size: 14px;

                    p {
                        margin: 0;
                    }

                    label {
                        font-size: 14px;
                    }

                    input {
                        width: 100%;
                        height: 40px;
                        padding: 10px;
                        border: 1px solid #ccc;
                        color: #333;
                        background: white;
                        border-radius: 5px;
                    }

                    .col {
                        padding: 5px;
                    }

                    .col-2 {
                        padding: 0;
                    }

                    .md-pending-share {
                        // color: #c42527;
                    }

                    .actions-wrapper {
                        display: flex;
                        align-items: flex-end;
                        justify-content: space-around;
                        margin-bottom: 15px;
                        font-size: 20px;

                        .btn-remove {
                            color: #c42527;
                            font-weight: bold;
                            font-size: 14px;
                        }

                        .btn-add {
                            color: #129145;
                            font-weight: bold;
                            font-size: 14px;

                            @media screen and (max-width: 575px) {
                                margin-right: 3px;
                            }

                            &.disabled {
                                color: #eee;
                            }
                        }
                    }
                }

                .btn-add-share {
                    font-weight: 700;
                    color: #009FD9;
                    padding-top: 5px;
                }

                .btn-add-share-disabled {
                    font-weight: 700;
                    color: #ccc;
                    padding-top: 5px;
                }
            }

            .modal-footer {

                .btn {
                    float: right;
                    width: 250px;
                    height: 50px;
                    border-radius: 0;
                    font-size: 16px;
                    font-weight: bold;

                    -webkit-clip-path: polygon(100% 0, 100% 63%, 93% 100%, 0 100%, 0 0);
                    clip-path: polygon(100% 0, 100% 63%, 93% 100%, 0 100%, 0 0);

                    @media screen and (max-width: 575px) {
                        width: 150px;
                        height: 40px;
                        font-size: 14px;
                    }
                }

                .btn:hover {
                    color: white;
                }
            }
        }
    }
}