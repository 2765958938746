.md-pros-modal {
  .modal-dialog {
    max-width: 700px !important;
    .modal-content {
      border-radius: 5px !important;
      .modal-header {
        h4 {
          margin: 0;
        }

        a {
          color: #2F3033;
        }
      }

      .modal-body {
        position: relative;

        .close-button {
          position: absolute;
          top: 15px;
          right: 15px;
          cursor: pointer;
        }

        p {
          font-size: 15px;
        }

        h4 {
          text-align: center;
          max-width: 60%;
          margin: 10px auto 5px;
        }

        .pro-card {
          position: relative;
          display: flex;
          padding: 30px 10px;
          border-bottom: 1px solid #eeeeee;
          width: 100%;

          .image-wrapper {
            img {
              border-radius: 2px;
            }
          }

          .pro-details {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 15px;

            .pro-name-wrapper {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;

              .pro-name {
                color: #2F3033;
                font-weight: 700;
              }

              span {
                color: #2F3033;
              }
            }

            .pro-review {
              font-style: italic;
              font-size: 14px;
              max-width: 70%;

              a {
                font-style: normal;
              }
            }

            .view-profile-link {
              position: absolute;
              bottom: 34px;
              right: 20px;
              display: flex;
              align-items: center;
              justify-content: flex-end;

              a {
                display: flex;
                align-items: center;
                background: #009FD9;
                color: #ffffff;

                i {
                  margin-left: 5px;
                }
              }
            }

            p {
              margin: 0;
              font-weight: 100;
              color: #2F3033;

              span {
                font-weight: 700;
                color: #2db783;
              }
            }

            a {
              font-size: 14px;
              font-weight: 700;
              border-radius: 3px;
            }
          }
        }

        .thumbtack-logo-header {
          padding: 0px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;

          p {
            margin: 0;
            font-weight: 100;
            color: #2F3033;
          }

          img {
            margin-left: 5px;
            width: 25px;
          }
        }

        .thumbtack-logo {
          padding: 20px 0 10px;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            margin: 0;
            font-weight: 100;
            color: #2F3033;
          }

          img {
            margin-left: 5px;
            width: 25px;
          }
        }
      }
    }
  }
}
