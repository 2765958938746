.md-settings-page {
  .PropertyCard-body {
    padding: 1.55rem;
    font-size: 14px;
    border-bottom: 1px solid #ccc;
    display: flex;
    flex-wrap: wrap;
    min-height: 300px;

    @media screen and (max-width: 575px) {
      padding-left: 5px;
      padding-right: 5px;
      display: block;
    }

    h4 {
      @media screen and (max-width: 575px) {
        font-size: 20px;
      }
    }

    .property-actions-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      .btn {
        width: calc(50% - 5px);
        margin-bottom: 5px;
        color: #ffffff;
      }

      .archive-tooltip-wrapper {
        position: relative;
        width: calc(50% - 5px);

        .btn {
          width: 100%;
        }

        .archive-info {
          background: #000000;
          border: 1px solid #000000;
          box-shadow: 0px 1px 5px 0px rgba(0, 55, 130, 0.18);
          color: #ffffff;
          padding: 5px;
          position: absolute;
          right: -10px;
          top: 39px;
          width: 160px;

          p {
            font-size: 12px;
            margin: 0;
          }

          &::before {
            content: "";
            position: absolute;
            top: -10px;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent #000000 transparent;
          }
        }
      }
    }

    .md-property-photos-container {
      height: 100%;
      display: grid;
      grid-gap: 5px;
      grid-template-columns: repeat(5, 100px);
      grid-template-rows: repeat(auto-fill, 100px);

      @media screen and (max-width: 1200px) {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
        grid-template-rows: repeat(3, minmax(100px, 1fr));
      }

      @media screen and (max-width: 767px) {
        grid-template-columns: repeat(3, minmax(100px, 1fr));
        grid-template-rows: repeat(4, minmax(100px, 1fr));
      }

      @media screen and (max-width: 575px) {
        margin-top: 20px;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        grid-template-rows: repeat(5, minmax(150px, 1fr));
      }

      @media screen and (max-width: 400px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(5, 100px);
      }

      .primary {
        grid-row-end: span 2;
        grid-column-end: span 2;
        overflow: hidden;
      }

      .secondary {
        grid-row-end: span 1;
        grid-column-end: span 1;
        overflow: hidden;
      }

      .md-property-settings-photo {
        background-size: cover !important;
        height: 100%;
        width: 100%;

        .md-remove-image {
          height: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          opacity: 0;
          transition: 0.3s;
          color: white;
          padding-right: 10px;
        }

        .md-remove-image-overlay {
          height: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          padding-bottom: 10px;
          padding-right: 10px;
          opacity: 1;
          transition: 0.3s;
          color: white;
          background: rgba(50, 50, 50, 0.3);

          i:hover {
            cursor: pointer;
            height: 15px;
            width: 15px;
          }
        }
      }

      .md-grid-map {
        background-size: cover !important;
        height: 100%;
        width: 100%;
      }

      .md-grid-streetview {
        grid-row-end: span 2;
        height: 100%;
        width: 100%;
        background-size: cover !important;
      }
    }

    .col-lg-4 {
      font-size: 16px;
    }

    .btn {
      font-size: 14px;
      letter-spacing: 0;
      height: auto;
    }

    hr {
      width: 100%;
    }
  }
}