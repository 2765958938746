.user-repair-card {
  margin: 10px 0px;
  background: #fafafa;
  border: 1px solid #e9eced;
  border-radius: 2px;
  transition: 0.4s;

  &:hover {
    box-shadow: 0px 2px 15px 0px rgba(0, 55, 130, 0.15);
  }

  .priority-tag-wrapper {
    padding: 0;
    position: relative;

    .priority-tag {
      align-items: center;
      justify-content: center;
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      margin-left: auto;
      margin-right: auto;
      max-height: 25px;
      min-width: 130px;
      border: 0;
      width: 100%;
      padding: 3px;
      position: relative;
      -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 5% 50%, 0 0);
      clip-path: polygon(100% 0, 100% 100%, 0 100%, 5% 50%, 0 0);

      &.md-Exclude {
        color: #777777;
      }

      &.tag-border {
        background: #aaaaaa;
        height: 24px;
        right: 0px;
        position: absolute;
        top: 0px;
        transform: scaleX(1.02) scaleY(1.1);
        width: calc(100% + 1px);
        z-index: -1;
      }

      p {
        font-size: 12px;
      }
    }
  }

  .repair-details {
    padding: 15px;
    transition: 0.2s;

    &:hover {
      cursor: pointer;
      background: #e8e9ea;
    }

    .repair-title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title-wrapper {
        display: flex;
        align-items: center;

        input {
          @media screen and (max-width: 575px) {
            margin-left: 0;
            width: 100%;
          }
        }

        p {
          font-weight: 700;
          margin: 0;
          max-width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          @media screen and (max-width: 575px) {
            max-width: 200px;
          }

          i {
            margin-left: 3px;
          }
        }
      }

      .priority-tag {
        text-transform: uppercase;
        margin: 0 0 0 auto;
        border: 0;
        padding: 3px;
        width: auto;
        min-width: 80px;

        p {
          font-size: 12px;
          font-weight: 500;
          width: auto;
        }
      }
    }

    .repair-info {
      align-items: center;
      display: flex;
      z-index: 1;

      .row {
        margin-right: 0;

        @media (max-width: 575px) {
          padding: 0 15px;

          div {
            padding: 0;
            margin-top: 5px;
          }
        }
      }

      .info-item {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        justify-content: center;

        p {
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          @media (max-width: 575px) {
            text-align: left;
          }
        }

        .md-total-cost {
          color: #aaaaaa;
          font-weight: normal;
          position: relative;

          &:before {
            position: absolute;
            content: "";
            left: 0;
            top: 50%;
            right: 0;
            border-top: 0.5px solid;
            border-color: inherit;
            max-width: 100px;
            margin: auto;

            -webkit-transform: rotate(-5deg);
            -moz-transform: rotate(-5deg);
            -ms-transform: rotate(-5deg);
            -o-transform: rotate(-5deg);
            transform: rotate(-5deg);
          }
        }

        .md-user-cost {
          width: 100%;
        }
      }
    }

    .repair-problem {
      margin-top: 10px;
      color: #575c5f;
      display: flex;
      align-items: center;

      p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  .repair-actions {
    padding: 15px;
    color: #575c5f;
    display: flex;
    align-items: center;
    border-top: 1px solid #cccccc;
    background: #ffffff;

    textarea {
      border: 1px solid #cccccc;
      border-radius: 2px;
      min-height: 100%;
      padding: 8px;
      width: 100%;

      @media (max-width: 575px) {
        margin-bottom: 10px;
      }
    }

    select {
      width: 100%;
      margin-bottom: 5px;

      &.default {
        color: #777777;
      }
    }

    .repair-options {
      padding: 0 15px 0 0;

      @media (max-width: 991px) {
        padding: 10px 15px;
      }
    }

    .upload-quote-button {
      background: #009fd9;
      border: 1px solid #009fd9;
      color: #ffffff;
      font-weight: 700;
      width: 100%;
    }

    .quote-details {
      &.deleting {
        color: #cccccc;

        a {
          color: #cccccc;
          cursor: default;
          text-decoration: none;
        }
      }

      .quote-actions {
        display: flex;

        button {
          background: transparent;
          border: 0;
          color: #2174a5;
          display: block;
          font-size: 14px;
          margin-right: 10px;
          outline: none;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .request-value-wrapper {
      position: relative;

      input {
        border: 1px solid #cccccc;
        border-radius: 2px;
        padding: 10px 15px;
        width: 100%;
      }

      p {
        position: absolute;
        top: 12px;
        left: 5px;
      }
    }

    .update-request-btn {
      font-weight: 700;
      height: auto;
      margin-top: 5px;
      padding: 5px;
      transition: 0.5s;
      width: 100%;

      &:hover {
        color: #ffffff;
      }

      &.disabled {
        background-color: #cccccc;
        border-color: #cccccc;
      }
    }
  }
}
