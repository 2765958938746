.sidebar {
  padding-left: 0;
  padding-right: 0;
  z-index: 1000;
  width: 100%;
  background-color: #f8f9fa;

  @media print {
    display: none;
  }

  @media screen and (min-width: 992px) {
    flex: 0 0 25%;
    max-width: 25%;
  }

  @media screen and (max-width: 991px) {
    position: fixed;
    top: 0;
    left: -350px;
    z-index: 100;
    box-shadow: 3px 2px 12px 2px rgba(0, 0, 0, 0.2);
    width: 340px;

    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
  }

  @media screen and (max-width: 575px) {
    max-width: 230px;
  }

  .header {
    height: 60px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .badge {
    margin-left: 5px;
  }

  .sticky-top {
    height: 100vh;
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    display: table;

    .img-wrapper {
      height: 220px;
      overflow: hidden;

      @media screen and (max-height: 690px) and (min-width: 991px) {
        height: 150px !important;
      }

      @media screen and (max-width: 991px) {
        height: 180px;
      }

      @media screen and (max-width: 575px) {
        height: 100px;
      }

      .img {
        background: #ffffff;
        height: 220px;
        display: flex;
        width: 100%;
        background-size: cover !important;

        @media screen and (max-width: 991px) {
          height: 180px;
        }

        @media screen and (max-width: 575px) {
          height: 140px;
        }
      }

      img {
        height: 220px;
        width: 100%;
      }
    }

    .bottom-bar {
      color: #ced0d0;
      display: flex;
      justify-content: space-between;
      font-family: "Mark", sans-serif;
      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ced0d0;
      padding: 6px 15px;

      p {
        margin: 0;
        color: white;
      }

      p:hover {
        text-decoration: none;
      }

      i {
        color: white;
      }
    }

    .bottom-bar:hover {
      cursor: pointer;
    }

    .property-dropdown {
      height: 0;
      max-height: 400px;
      opacity: 0.7;
      position: absolute;
      width: 100%;
      z-index: -1;
      padding: 20px;
      padding-top: 10px;
      overflow-y: hidden;
      overflow-x: hidden;
      transition: 0.3s;
      text-transform: uppercase;

      @media screen and (max-width: 575px) {
        max-height: 350px;
      }

      .property-dropdown-subheader {
        color: white;
        font-size: 14px;
        font-weight: 700;
        margin: 0;
        position: sticky;
        top: -10px;
        // background: #5275b1;
        padding: 5px 20px;
        // max-width: 350px;
        margin-left: -20px;
        margin-right: -20px;
        letter-spacing: normal;
        text-transform: capitalize;

        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }

      &.active {
        z-index: 1;
        height: 400px;
        opacity: 1;
        overflow-y: scroll;
        transition: 0.3s;
      }

      a {
        display: flex;
        align-items: center;
        padding: 8px 10px 8px 0px;
        color: white;
        margin-top: 5px;
        border-bottom: 1px solid #009fd9;
        font-size: 14px;
        letter-spacing: normal;

        &:hover {
          color: white !important;
        }

        @media screen and (max-width: 575px) {
          font-size: 12px;
        }

        .thumbnail-img {
          height: 50px;
          width: 50px;
          margin-right: 10px;
        }
      }

      .md-reports-toggle {
        border: 0;
        font-size: 12px;
        justify-content: center;
      }
    }

    .address-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-flow: row wrap;
      background: #009fd9;
      padding: 15px;

      @media screen and (max-width: 575px) {
        height: 55px;
        padding: 10px;
        padding-left: 15px;

        p {
          font-size: 12px;
        }
      }

      p {
        margin-bottom: 0;
      }

      .address {
        font-weight: 200;
        font-family: "Mark", sans-serif;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #ffffff;
      }
    }

    .nav {
      height: 100%;
      flex-wrap: nowrap;
      overflow: scroll;

      @media screen and (max-width: 575px) {
        height: 40%;
      }

      a {
        font-family: "Mark", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #272525;
        text-decoration: none;
      }

      .nav-link {
        font-size: 12px;
        padding: 8px 23px;
        color: #2174a5;
        margin-left: auto;
      }

      .nav-link:hover {
        text-decoration: none;
      }

      .nav-subheader {
        display: flex;
        justify-content: space-between;
        font-family: "Mark", sans-serif;
        font-size: 12px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fff;
        margin-top: 5px;
        margin-bottom: 3px;
        padding: 6px 23px;
        background: #325796;
      }
    }

    .nav-item {
      padding: 8px 15px;
      display: flex;
      align-items: center;
      font-size: 14px;
      transition: 0.2s;

      &.active,
      &:hover {
        background-color: #B2EBFF;

        a {
          font-family: "Mark", sans-serif;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #009fd9;
          text-decoration: none;
        }
      }

      span.icon {
        display: inline-flex;
        margin-right: 15px;
        width: 30px;
        height: 30px;
        align-items: center;
        justify-content: center;
      }
      a {
        font-family: "Mark", sans-serif;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #272525;
      }
    }

    .link-wrapper {
      background-color: #f8f9fa;
      display: table-row;
      height: 100%;
      width: 100%;

      @media screen and (max-width: 575px) {
        padding: 10px;

        li.nav-item {
          font-size: 12px;
          padding: 0;

          span {
            margin-right: 10px !important;
          }

          img {
            width: 20px;
          }
        }
      }
    }

    .bottom-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      background: #e7e9ea;
      width: 100%;
      padding: 20px 0px;
      margin: 0;

      a {
        font-family: "Mark", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #272525;
        text-decoration: none;
      }

      .nav-item {
        padding: 0 15px;
      }

      @media screen and (max-width: 575px) {
        padding: 10px;

        li.nav-item {
          font-size: 12px;
          padding: 0;

          span {
            margin-right: 10px !important;
          }

          img {
            width: 20px;
          }
        }
      }
    }
  }
}

.show {
  left: 0;

  .sticky-top {
    display: block;

    .link-wrapper {
      display: flex;
      padding: 0;

      .nav-item {
        @media screen and (max-width: 575px) {
          padding: 2px 10px !important;
        }
      }
    }
  }
}
