.md-settings-page {
  @media print {
    display: none;
  }

  width: 100%;
  font-family: "Roboto", sans-serif;
  padding: 20px;
  input[type="text"],
  input[type="email"],
  select {
    border: 1px solid #ccc;
    border-radius: 3px;
    height: 50px;
    width: 100%;
    color: #000000;
  }
  select {
    padding: 5px;
    font-size: 16px;
  }
  label {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0;
    color: #272525;
  }
  .flex-row {
    display: flex;
    justify-content: flex-start;
    flex-flow: row nowrap;
    align-items: flex-start;
    > div {
      // width: calc(50% - 16px);
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  // .affiliate-message {
  //   background: #129145ee;

  //   .card-title {
  //     background-color: #129145 !important;
  //     color: #ffffff;
  //     margin-bottom: 15px !important;
  //   }

  //   p {
  //     color: #ffffff;
  //     font-weight: 400;
  //     padding: 0 15px;
  //     font-size: 16px;
  //   }

  //   a {
  //     color: #ffffff !important;
  //     text-decoration: underline;
  //   }
  // }

  .card {
    margin-top: 20px !important;
    margin: 15px auto;
    box-shadow: 5px 10px 30px 0 rgba(0, 55, 130, 0.15);
    border: none;
    border-radius: 6px;
    padding: 0;
    // width: calc(50% - 10px);

    @media screen and (max-width: 991px) {
      margin-top: 50px !important;
    }

    .card-title {
      height: 75px;
      border-radius: 6px 6px 0 0;
      background-color: #eceded;
      padding: 20px;
      font-family: Roboto;
      font-size: 24px;
      font-weight: 900;
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      a {
        font-size: 14px;
      }

      .btn {
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        text-transform: uppercase;
      }
    }

    &.md-details {
      @media screen and (max-width: 575px) {
        margin-top: 50px !important;
      }
    }

    &.md-partner-details {
      label {
        width: 100%;
        display: flex;
        justify-content: space-between;

        a {
          color: #2174a5 !important;
          font-weight: 400;
        }
      }

      .invalid {
        border: 1px solid #c42527;
        color: #c42527;
      }

      .alert {
        i:hover {
          cursor: pointer;
        }
      }

      .cropped-result {
        position: relative;
        width: 33.33%;
        margin-right: auto;

        img {
          min-height: 150px;
          max-width: 100%;
          transition: 0.5s;

          &:hover {
            opacity: 0.8;
            cursor: pointer;
          }
        }

        .cropped-result-overlay {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          opacity: 0;
          transition: 0.2s;

          &:hover {
            opacity: 100;
            cursor: pointer;
          }

          i {
            font-size: 40px;
            color: #ffffff;
          }
        }
      }

      @media screen and (max-width: 575px) {
        margin-top: 50px !important;
      }
    }

    button {
      &.btn-thumbtack-outline {
        background: transparent;
        border-radius: 5px;
        font-size: 20px;
        border: solid 2px #009FD9;
        height: 100%;
        width: 100%;

        &:hover {
          background: #009FD9;
        }
      }
    }

    .details-card-body {
      font-size: 14px;
      padding: 20px 10px;
      display: flex;
      flex-wrap: wrap;

      @media screen and (max-width: 575px) {
        padding-left: 5px;
        padding-right: 5px;
        display: block;
      }

      a {
        color: #fff;
        font-size: 14px;
      }

      button {
        &.btn-mdblue-outline {
          border-radius: 5px;
          border: solid 2px #123dba;
          color: #123dba;
          height: 100px;
          width: 100px;
          &:hover {
            color: #fff;
            background: #123dba;
          }
        }
      }

      .icon-input-wrapper {
        position: relative;

        i {
          font-size: 20px;
          position: absolute;
          top: 13px;
          right: 13px;
          padding: 2px;
          color: #2174a5;

          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }

      .user-type-wrapper {
        display: flex;
        align-items: center;
        padding: 0 0 10px;

        input {
          margin: 0;
        }

        .user-type-label {
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #788995;
          margin-bottom: 0;
          margin-left: 10px;
          margin-right: 15px;

          a {
            color: #2174a5;
          }
        }
      }

      .profile-img {
        background-size: cover !important;
        max-width: 200px;
        height: 200px;
        margin-bottom: 20px;

        .img-overlay {
          width: 100%;
          height: 100%;
          background: rgba(50, 50, 50, 0);
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          padding-right: 10px;
          opacity: 0;
          transition: 0.2s;

          i {
            color: white;
            cursor: pointer;
          }
        }
      }

      .profile-img:hover {
        .img-overlay {
          background: rgba(50, 50, 50, 0.4);
          padding-bottom: 10px;
          opacity: 1;
          transition: 0.2s;
        }
      }
    }

    a {
      color: #007aff;
    }

    .md-spinner-container {
      height: auto;
      padding: 40px;
    }
  }

  .md-terms {
    margin-bottom: 30px;
    padding: 0;
  }
  ul.settings-checklist {
    padding: 0;
    list-style: none;
    li {
      background: url("https://image.flaticon.com/icons/svg/907/907881.svg")
        no-repeat;
      height: 20px;
      padding-left: 30px;
      margin-bottom: 15px;
      color: #123dba;
    }
  }

  .md-input-warning {
    font-size: 12px;
    color: #c42527;
  }
}

.btn-cut-bottom-left {
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 8% 100%, 0% 60%);
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 8% 100%, 0% 60%);
}

.map-marker {
  box-shadow: 0px 0px 4px 4px rgba(58, 132, 255, 0.4);
  background-color: #3a84ff;
  display: inline-flex;
  transform: translate(-50%, -50%);
  height: 16px;
  width: 16px;
  border-radius: 50%;
  z-index: 1000;
}

.map-marker-text {
  position: absolute;
  top: -50px;
  background: white;
  width: 150px;
  height: 50px;
  padding: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

  p {
    margin: 0;
    padding-left: 15px;
    text-indent: -5px;
  }
}

.md-pending-property {
  padding: 40px;
  text-transform: uppercase;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .action-wrapper {
    display: flex;
    align-items: center;

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 12px;
      letter-spacing: 0.9px;
      height: 38px;
      margin-left: 10px;

      @media screen and (max-width: 575px) {
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }

  a {
    color: white !important;
  }

  h4 {
    margin: 0;
    @media screen and (max-width: 575px) {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 575px) {
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;

    a {
      width: 100%;
    }

    h4 {
      margin-bottom: 10px;
    }
  }
}

.add-property-footer {
  text-align: center;
  margin-bottom: 20px;
  margin: 0 40px 20px 40px;

  .toggle-archive {
    font-size: 14px;
  }

  .btn {
    margin-top: 15px;
    height: 50px;
    width: 217px;
    padding: 0.8rem 1rem;
    color: white !important;
    border-radius: 0;
    // font-size: 14px;
  }
}

.md-domoscore-card-container {
  padding: 0 40px;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-auto-rows: 380px;
  margin-top: 40px;
  margin-bottom: 40px;

  @media screen and (max-width: 575px) {
    grid-auto-rows: 300px;
  }

  .md-spinner-container {
    height: 100%;
    border: 1px solid #eee;
    border-radius: 5px;
  }

  .md-domoscore-card {
    border: 1px solid #eee;
    border-radius: 5px;

    .card-image {
      width: 100%;
      height: 60%;

      @media screen and (max-width: 575px) {
        height: 50%;
      }

      .md-grid-streetview {
        width: 100%;
        height: 100%;
        margin-bottom: 10px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }

    .card-info {
      padding: 10px;

      .address {
        font-size: 14px;
        margin-bottom: 6px;
      }

      .domoscore-action {
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: #007aff;
        border-radius: 5px;
        font-size: 14px;
        padding: 7px;
        text-decoration: none;
      }

      .nickname-container {
        font-size: 14px;
        margin: 0;

        .nickname {
          display: flex;
          align-items: center;
          padding: 3px 0px;

          p {
            font-weight: 900;
            font-size: 14px;
            margin: 0;
          }
        }

        .nickname-input {
          padding: 0;
          padding-bottom: 5px;

          input {
            width: 100%;
            height: 30px;
          }
        }

        .nickname-action {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
        }
      }
    }
  }
}

.modal-backdrop.show {
  @media print {
    opacity: 0;
  }
}

.md-image-modal {
  .modal-dialog {
    max-width: 750px !important;

    .modal-content {
      border: 0;

      @media screen and (max-width: 400px) {
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 450px;
      }

      .modal-body {
        font-size: 16px;

        h4 {
          font-size: 18px;
        }
      }

      .modal-footer {
        align-items: center;
        justify-content: center;

        .md-confirm-wrapper {
          p {
            text-align: center;
          }

          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }

        button {
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
  }
}

.md-order-modal {
  .modal-dialog {
    max-width: 750px !important;

    @media print {
      max-width: 100vw !important;
      max-height: 100vh !important;
    }

    .modal-content {
      border: 0;

      @media print {
        width: 100vw;
        border-radius: 0px !important;
        border: 1px solid #333;
        border-right: 2px solid #333;
        position: absolute;
        top: 0;
      }

      .modal-header {
        background-color: #eee;
        display: flex;
        align-items: center;

        .md-print-receipt {
          // color: #ffffff;
          margin-left: 5px;

          @media print {
            display: none;
          }
        }

        img {
          display: none;

          @media print {
            display: inline;
          }
        }

        button {
          @media print {
            display: none;
          }
        }
      }

      .modal-body {
        font-size: 14px;

        @media screen and (max-width: 575px) {
          padding: 0;
        }

        hr {
          margin: 0;
        }

        .md-dark {
          background-color: #eee;
        }

        h4 {
          font-size: 14px;
        }

        .row {
          padding: 15px;

          .col-6 {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @media screen and (max-width: 575px) {
              justify-content: end;
            }
          }
        }
      }
    }
  }

  .md-confirm-modal {
    .modal-dialog {
      max-width: 550px !important;

      .modal-content {
        border: 0;

        .modal-body {
          font-size: 16px;
          text-align: center;

          p {
            margin: 0;
          }
        }

        .modal-footer {
          align-items: center;
          justify-content: center;

          button {
            margin-left: 5px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
