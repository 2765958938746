.md-demo-modal {

    .modal-header {
        h4 {
            margin: 0;
        }
    }

    .modal-body {
        padding: 0;

        .demo-img-wrapper {
            align-items: center;
            background: #eeeeee;
            display: flex;
            justify-content: center;
            padding: 20px;
    
            img {
                border: 1px solid #cccccc;
                border-radius: 5px;
                box-shadow: 0 0 10px 5px rgba(100, 100, 100, 0.05);
                width: 350px;
            }
        }
    
        .demo-info-wrapper {
            font-size: 14px;
            padding: 30px;
    
            p {
                margin: 0;

                a {
                    font-weight: 700;
                }
            }
        }
    }

    .modal-footer {
        align-items: center;
        display: flex;
        justify-content: center;

        .btn {
            border-radius: 3px;
            font-size: 14px;
            font-weight: 700;
            padding: 8px 15px;
            width: calc(50% - 10px);
            margin: 5px;
            text-transform: capitalize;
        }
    
        .btn-cancel {
            background-color: #ffffff;
            border: 1px solid #2F3033;
            color: #2F3033;
    
            @media (max-width: 575px) {
                margin-bottom: 30px;
            } 
    
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    
        .btn-tertiary:hover {
            color: #ffffff
        }
    }
}