.md-documents-page {
    padding: 0;

    .md-documents-body {
        overflow: hidden;
        padding: 30px;
        font-size: 16px;

        @media screen and (max-width: 991px) {
            padding: 0 1rem;
        }
    
        .btn-info {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            margin-bottom: 40px;
            border: 0;
            border-radius: 0;
            font-weight: bold;
            width: 250px;
            height: 57px;
            -webkit-clip-path: polygon(100% 0%, 100% 100%, 17px 100%, 0% 65%, 0% 0%);
            clip-path: polygon(100% 0%, 100% 100%, 17px 100%, 0% 65%, 0% 0%);
            margin-bottom: 150px;
    
            @media screen and (max-width: 991px) {
                margin-bottom: 40px;
            }
        }
        
        .md-docs-container {
            padding: 0 15px;
            display: grid;
            grid-gap: 20px 0px;
            grid-template-columns: repeat( auto-fill, minmax( 280px, 1fr ) ); 
            grid-auto-rows: 280px; 
            margin-top: 30px;
            min-height: 100vh;
    
            .doc-container {
                font-size: 14px;
                overflow: hidden;
                filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.04));
            }
    
            .md-doc {
                height: 100%;
                width: 100%;
                background: white;
                padding: 25px;
                -webkit-clip-path: polygon(100% 0px, 100% 0px, 100% 100%, -200px 100%, 30px 0);
                clip-path: polygon(100% 0px, 100% 0px, 100% 100%, -200px 100%, 30px 0);
                
                h4 {
                    font-family: "Mark";
                    font-weight: bold;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #000000;
                    overflow-wrap: break-word;
                }
    
                p {
                    font-family: "Mark";
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.69;
                    letter-spacing: normal;
                    color: #63666a;
                }
    
                .icon {
                    padding: 0;
                }
    
                .dropdown-toggle {
                    background-color: transparent !important;
                    color: black !important;
                    border: 0 !important;
                    padding: 0;
                    font-size: 20px;
                }
    
                .dropdown-toggle:focus {
                    box-shadow: none;
                }
                
                .dropdown-menu {
                    position: absolute;
                    top: 0px;
                    left: -150px !important;
                    transform: translate3d(0, 0, 0) !important;
                    border-radius: 0;
                    background: black;
                    color: white;
    
                    a {
                        color: white;
                        background: #000000;
                    }
    
                    a:hover {
                        background-color: #333;
                    }
                }
    
                .md-docs-dropdown {
                    margin: 0;
                    background: black;
                    color: white;
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 150px;
    
                    ul {
                        list-style: none;
                        padding: 10px;
                        margin: 0;
                    }
    
                    li {
                        margin: 5px;
                    }
                }
    
                &.md-doc-add {
                    background-color: transparent;
                    border: 2px solid #ccc;
                    border-radius: 5px;
                    -webkit-clip-path: none;
                    clip-path: none;
                    cursor: pointer;
    
    
                    .md-doc-add-btn {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 40px;
                        color: #ccc;
                    }
                }
            }
        }
    }
}

.md-documents-modal {

    .modal-content {
        border: 0;

        .modal-header {
            align-items: center;
            background-color: #eee;
        }

        .modal-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 30px;

            @media screen and (max-width: 767px) {
                padding: 20px;
            }

            .md-file-input {
                margin-bottom: 10px;
                display: flex;
                width: 100%;
                height: 252px;
                border-radius: 10px;
                border: dashed 2px rgba(10, 55, 130, 0.25);
        
                p {
                    display: block;
                    align-items: center;
                    margin-left: auto;
                    margin-right: auto;
                    text-align: center;
                    color: #788995;
                }

                i {
                    margin-right: 10px;
                    color: #788995;
                }
        
                .file-drop {
                    width: 100%;
                    align-items: center;
                    display: flex;
        
                    .file-drop-target {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }

            label {
                margin-top: 5px;
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;
                color: #777;
            }

            input {
                border: 1px solid #eee;
                height: 40px;
            }

            .md-character-count {
                font-size: 14px;
                font-weight: 700;
                text-align: right;
                color: #777;
            }

            textarea {
                border: 1px solid #eee;
            }

            button {
                width: 30%;
                height: 45px;
                margin-left: auto;
                font-weight: bold;
                border-radius: 0;
                color: #000;
                -webkit-clip-path: polygon(13% 0, 100% 0, 100% 100%, 0 100%, 0 37%);
                clip-path: polygon(13% 0, 100% 0, 100% 100%, 0 100%, 0 37%);
            }

            .md-spinner-container {
                height: 100%;
                justify-content: flex-end;
                padding-right: 10px;

                .md-loading-spinner {
                    width: 2em;
                    height: 2em;
                }
            }
        }
    }
}