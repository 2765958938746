.request-list-printout-wrapper {
  background: #dddddd;
  padding: 40px;
  position: static;

  @media print {
    background: #ffffff;
    padding: 0;
    max-height: none;
    overflow: visible;
  }

  .request-list-printout {
    background: #ffffff;
    border: 1px solid #eeeeee;
    box-shadow: 0px 1px 15px 5px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    max-width: 1100px;
    margin: 0 auto;

    @media print {
      margin: 0 10px;
      border: 0;
      box-shadow: 0;
      width: 1125px;
      max-height: 1300px;
    }

    .page-break {
      page-break-after: always;
    }

    .page-break-before {
      page-break-before: always;
    }

    .react-pdf__Document {
      padding: 20px;
      line-height: initial;

      @media print {
        margin: auto;
      }

      .react-pdf__Page__canvas {
        width: 100% !important;
        height: auto !important;
        margin: 10px 0;
        border: 5px solid #000000;
      }

      .react-pdf__Page__textContent {
        @media print {
          display: none;
        }

        span {
          transform: scaleX(0.975898) translateY(9.47266%) !important;
        }
      }
    }

    p {
      margin: 0;
    }

    span {
      font-weight: 700;
    }

    .logo-section {
      display: flex;
      align-items: center;

      img {
        width: 170px;
      }

      p {
        color: #63666A;
        font-size: 14px;
        font-weight: 300;
      }
    }

    .request-list-header {
      background: #F6F7F7;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;

      p {
        color: #63666A;
        font-size: 14px;
      }

      .page-count {
        p {
          font-weight: 700;
        }
      }
    }

    .property-details-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      .property-image-wrapper {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 280px;
        padding: 0;

        img {
          width: 100%;
        }
      }

      .property-details {
        padding: 20px 50px;

        .md-header {
          h4 {
            color: #009FD9;
            font-size: 50px;
            margin: 0 0 0 -7px;

            @media (max-width: 575px) {
              font-size: 55px;
            }

            span {
              font-weight: 300;
            }
          }
        }

        .property-info {
          display: flex;
          flex-direction: row;
          margin-top: 20px;

          .info-wrapper {
            padding-right: 40px;

            .info-label {
              color: #9B9B9B;
              font-size: 12px;
              text-transform: uppercase;
            }

            .info-content {
              font-weight: 500;
              font-size: 18px;
              color: #272525;
            }
          }
        }
      }
    }

    .request-list-summary {
      background: #F6F7F7;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: row;
      padding: 40px 40px 10px;

      .row {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .list-item {
        display: flex;
        align-items: center;
        margin-bottom: 25px;

        .list-img-wrapper {
          padding: 10px;
          margin-right: 10px;
          border-radius: 50%;
          background: rgba(0, 55, 130, 0.13);
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 25px;
          }
        }

        p {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          font-size: 14px;
          margin: 0;

          span {
            font-size: 16px;
            font-weight: 700;
            margin-right: 4px;
            width: 100%;
          }
        }
      }
    }

    .request-list-notes-wrapper {
      padding: 40px;

      .request-list-notes {
        border: 1px solid #dddddd;

        .request-list-notes-header {
          background: #FAFAFA;
          padding: 10px 15px;

          h4 {
            color: #009FD9;
            font-weight: bold;
            font-size: 16px;
            letter-spacing: normal;
          }
        }

        .request-list-notes-content {
          padding: 15px;

          p {
            letter-spacing: normal;
            white-space: pre-wrap;
          }
        }
      }
    }

    .request-list-breakdown {
      span {
        font-weight: 700;
      }

      .breakdown-header {
        background: #009FD9;
        color: #ffffff;
        padding: 10px 15px;

        .header-title {
          display: flex;
          align-items: center;

          h4 {
            margin: 0;
          }
        }

        .header-cost {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-right: 30px;
        }
      }

      .breakdown-section {
        .breakdown-subheader {
          padding: 10px 15px;

          .subheader-title {
            color: #009FD9;
            display: flex;
            align-items: center;

            h4 {
              margin: 0;
            }
          }

          .subheader-cost {
            color: #009FD9;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 30px;
          }
        }

        .breakdown-request {
          border: 1px solid #dddddd;
          border-radius: 3px;
          break-inside: avoid !important;
          margin: 0px 10px 15px 10px;

          .request-header-wrapper {
            .request-header {
              background: #FAFAFA;
              padding: 10px;

              &.no-border {
                border-bottom: 0;
              }

              .request-title {
                display: flex;
                align-items: center;
              }

              .request-cost {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                ;
              }
            }
          }

          .request-content {
            border-top: 1px solid #dddddd;
            padding: 10px 25px;
          }
        }
      }
    }

    .request-list-disclaimer {
      font-size: 14px;
      margin-bottom: 10px;
      break-inside: avoid;
      page-break-after: always !important;

      .disclaimer {
        border: 1px solid #eeeeee;
        margin: 0 20px;
        padding: 20px;
      }
    }

    .request-list-details {
      padding: 0;

      .details-section-header {
        color: #000000;
        padding: 45px;
      }

      .details-card {
        border: 1px solid #dddddd;
        border-radius: 3px;
        margin: 20px;
        break-inside: avoid !important;

        p {
          font-size: 14px;
        }

        .details-info {
          background: #FAFAFA;
          padding: 30px 40px;

          .details-header {
            .details-title {
              display: flex;
              align-items: center;

              h4 {
                color: #009FD9;
                font-size: 20px;
                margin: 0;
              }
            }

            .details-cost {
              display: flex;
              align-items: center;
              justify-content: flex-end;

              p {
                font-size: 18px;
                font-weight: 500;
              }
            }
          }

          .details-quantity {
            color: #000000;
            margin-right: 5px;
          }

          .details-page-number {
            color: #000000;
          }

          .details-subheader {
            color: #000000;
            font-weight: 500;
            margin: 20px 0 0;
            text-transform: uppercase;
          }

          p {
            color: #4A4A4A
          }
        }

        .details-notes {
          border-top: 1px solid #dddddd;
          padding: 20px 40px;
        }
      }
    }

    .print-footer {
      color: #9B9B9B
    }
  }

    .md-disclaimer {
      color: #777;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 20px;
      background-color: #FAFAFA;
  
      .satisfaction-text {
        font-size: 14px;
        text-align: center;
        margin-bottom: 20px;
      }
  
      p {
        margin: 0;
      }
    }

  .request-list-appendix {
    page-break-before: always !important;

    .md-pdf-page {
      break-inside: avoid;
    }
  }

}
