.print-sidebar {
  background: #f8f8f8;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
  height: 100vh;
  position: relative;
  padding: 0;

  @media print {
    display: none !important;
    visibility: hidden !important;
    opacity: 0 !important;
  }

  @media screen and (max-width: 991px) {
    background: #f8f8f8;
    box-shadow: 3px 2px 15px 0 rgba(0, 0, 0, 0.1);
    min-width: 400px;
    padding: 0px;
    position: fixed;
    top: 0;
    left: 0px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    width: 50%;
    z-index: 100;

    &.show {
      left: 0;
    }

    &.hide {
      left: -100%;
    }
  }

  @media screen and (max-width: 575px) {
    height: calc(100vh - 60px);
  }

  @media (max-width: 575px) {
    min-width: 285px;
    // width: 100%;
  }

  .sidebar-content {
    .sidebar-body {
      max-height: calc(100vh - 86px);
      overflow: scroll;
      padding: 40px 40px 160px;

      @media (max-width: 1200px) {
        padding: 20px 20px 160px;
      }

      @media screen and (max-width: 991px) {
        max-height: calc(100vh - 85px);
        padding: 20px 20px 80px;
      }

      .sidebar-header {
        .back-button {
          align-items: center;
          color: #2F3033;
          display: flex;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 10px;
          text-transform: uppercase;

          img {
            margin-right: 10px;
            width: 22px;
          }
        }
        h2 {
          font-size: 45px;

          @media (max-width: 575px) {
            font-size: 30px;
          }
        }

        h4 {
          font-size: 20px;
          font-weight: 500;
          margin: 0 0 0 2px;
          text-transform: uppercase;

          @media (max-width: 575px) {
            font-size: 18px;
          }
        }
      }

      .sidebar-input-wrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        position: relative;

        label {
          color: #009FD9;
          font-weight: 700;
          margin-bottom: 5px;
        }

        input {
          border: 0px solid #eeeeee;
          border-radius: 2px;
          box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
          padding: 15px 15px;
          -webkit-appearance: none;

          @media (max-width: 575px) {
            padding-right: 50px;
          }
        }

        select {
          border: 0px solid #eeeeee;
          border-radius: 2px;
          box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
          padding: 15px 15px;
          -webkit-appearance: none;

          @media (max-width: 575px) {
            padding-right: 50px;
          }
        }

        i {
          position: absolute;
          right: 25px;
          top: 50px;
        }
      }

      .options-label {
        color: #009FD9;
        font-weight: 700;
        margin-bottom: 5px;

        .fa-info-circle:hover {
          cursor: pointer;
        }
      }

      .inspection-page-options {
        background: #ffffff;
        border: 0px solid #eeeeee;
        border-radius: 3px;
        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
        display: flex;
        justify-content: space-between;
        padding: 15px 20px 0;

        @media (max-width: 1200px) {
          flex-direction: column;
        }

        .options-wrapper {
          width: 100%;

          h4 {
            color: #2F3033;
            font-size: 14px;
            text-transform: uppercase;
          }
        }

        .sidebar-checkbox-wrapper {
          align-items: center;
          display: flex;
          margin: 0 0 15px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          input {
            border: 1px solid #eeeeee;
            height: 16px;
            padding: 5px;
            width: 16px;
          }

          label {
            color: #000000;
            cursor: pointer;
            font-size: 14px;
            font-weight: normal;
            margin: 0 0 0 5px;
          }
        }
      }
    }
  }

  .sidebar-footer {
    background: #ffffff;
    bottom: 0;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
    position: absolute;
    width: 100%;

    @media (max-width: 575px) {
      width: 100%;
    }

    .btn {
      border-radius: 3px;
      font-weight: 700;
      letter-spacing: normal;
      padding: 10px;
      width: calc(50% - 10px);

      @media (max-width: 575px) {
        padding: 5px;
        margin-bottom: 5px;
      }

      &.btn-copy {
        border: 1px solid #2F3033;
        color: #2F3033 !important
      }

      &.btn-print {
        color: #ffffff;
        background: #009FD9;
        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
      }
    }
  }
}
