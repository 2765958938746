.md-explore-page {
    overflow: hidden;

    .md-explore-body {
        padding: 30px;
        font-size: 16px;
    
        @media screen and (max-width: 991px) {
            padding: 0 1rem;
            margin-bottom: 0px;
        }
    
        .md-explore-header-container {
            align-items: flex-start;
            color: #4a4a4a;

            @media screen and (max-width: 575px) {
                padding: 0;
            }
    
            hr {
                width: 30%;
                margin-left: 0;
                border: 0.5px solid #979797;
            }
    
            .md-explore-filters {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
    
                .md-explore-filter {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 5px;
                    min-width: 50px; 
                    color: #7c7c7c;
                    font-size: 16px;
                    padding: 5px 15px;
                    transition: 0.2s;

                    @media screen and (max-width: 767px) {
                        border-left: 0.5px solid #eee;
                    }
                }
    
                .md-explore-filter.active {
                    background-color: rgba(10, 55, 130, 0.25);
                    color: #325796;
                    font-weight: 600;
                }
    
                .md-explore-filter:hover {
                    background-color: rgba(10, 55, 130, 0.25);
                    color: #325796;
                    cursor: pointer;
                }
            }
        }
    
        .md-explore-card-container {
            padding: 0 15px;
            display: grid;
            grid-gap: 15px;
            grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
            grid-auto-rows: 350px;
            margin-top: 40px;
            min-height: 100vh;
            font-size: 14px;

            @media screen and (max-width: 575px) {
                padding: 0 20px;
            }
    
            .md-explore-card {
                width: 100%;
                max-height: 350px;
                background: white;
                border-radius: 5px;
                box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.08);
                transition: 0.5s;
    
                .md-explore-photo {
                    background-size: cover; 
                    height: 200px;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                }
    
                .md-explore-info-container {
                    padding: 15px;
                }
    
                .col-1 {
                    padding: 0;
                }
    
                i {
                    font-size: 1.5rem;
                }
            }
    
            .md-explore-card:hover {
                cursor: pointer;
                transform: scale(1.03);
            }
    
            .md-suggest-card {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 95%;
                height: 90%;
                padding: 10px;
                margin: auto;
                text-align: center;
                color: #325796;
                border: 3px dashed rgba(10, 55, 130, 0.25);
                border-radius: 5px;
                box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.08);
                transition: 0.5s;
    
                i {
                    margin-bottom: 15px;
                }
            }
    
            .md-suggest-card:hover {
                cursor: pointer;
                transform: scale(1.03);
            }
    
        }
    }
}

.md-explore-modal {
    .modal-dialog {

        .modal-content {
            font-size: 14px;

            .modal-header {
                display: block;
                border-radius: 0;

                .close {
                    opacity: 1;
                    text-shadow: none;

                    span {
                        color: white;
                        font-size: 40px;
                        font-weight: 100;
                        text-shadow: 0 0px 10px rgba(0, 0, 0, 0.48);
                    }
                }
            }

            .modal-body {
                p {
                    margin: 0;
                    margin-top: 5px;

                    a {
                        overflow-wrap: break-word;
                    }
                }
                
                .btn {
                    border-radius: 0;
                    margin-bottom: 15px;
                    padding: 15px;
                    min-width: 50%;
                    font-size: 14px;
                    font-weight: 700;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.06;
                    letter-spacing: normal;
                    color: #010e23;
                }

                .btn-primary {
                    margin-top: 5px;
                    -webkit-clip-path: polygon(93% 0, 100% 40%, 100% 100%, 0 100%, 0 0);
                    clip-path: polygon(93% 0, 100% 40%, 100% 100%, 0 100%, 0 0);
                }

                .btn-secondary {
                    -webkit-clip-path: polygon(100% 0, 100% 100%, 7% 100%, 0 70%, 0 0);
                    clip-path: polygon(100% 0, 100% 100%, 7% 100%, 0 70%, 0 0);
                }

                .col-10 {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;

                    .md-card-title {
                        margin: 0;
                        margin-right: 10px;
                    }

                    .md-explore-tag {
                        padding: 5px 15px;
                        background: #c5d0e1;
                        border-radius: 3px;
                        margin: 5px;
                        margin-left: 0;
                        color: #325796;
                        font-weight: 700;
                        text-transform: capitalize;
                    }
                }

                .col-2 {
                    i {
                        font-size: 1.5rem;
                    }
                }

                i {
                    margin-top: 5px;
                }

                i:hover {
                    cursor: pointer;
                }
            }

        }

    }
}