@page {
  margin: 0;
}

.request-list-print-options {
  margin: 0;
  max-width: 100%;
  position: relative;

  .row {
    flex: 1 0 100%;
  }

  .request-list-header {
    align-items: center;
    padding: 10px 20px;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 100;
    background-color: #ffffff;

    @media print {
      display: none !important;
    }

    a {
      i {
        font-size: 20px;
      }
    }

    .md-logo-wrapper {
      margin: auto;

      img {
        max-height: 30px;

        @media (max-width: 575px) {
          max-height: 25px;
        }
      }
    }
  }

  .printout-wrapper {
    padding: 0;
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100vh;
    background: #dddddd;

    @media print {
      height: auto;
      overflow: visible;
      flex: 0 0 100%;
      max-width: 100%;
    }

    @media screen and (max-width: 991px) {
      margin-top: 50px;
    }

    @media screen and (max-width: 575px) {
      height: calc(100vh - 44px);
    }

    #wrap {
      position: relative;
      width: 1100px;
      height: 100%;
      float: left;

      @media print {
        -webkit-transform: scale(1) !important;
        width: 1155px !important;
        height: 1300px !important;
      }
    }

    #outer {
      position: relative;
      width: 1100px;
      height: auto;
      -webkit-transform-origin: top left;

      @media print {
        -webkit-transform: scale(1) !important;
        width: 1155px !important;
        height: 1300px !important;

        background: blue;
      }
    }

    .scroll-back-button {
      align-items: center;
      animation: zoom-in 0.5s;
      background: #009FD9;
      border-radius: 50%;
      box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
      color: #ffffff;
      cursor: pointer;
      display: flex;
      height: 60px;
      justify-content: center;
      position: fixed;
      top: 50px;
      right: 30px;
      width: 60px;

      i {
        font-size: 30px;
        margin-bottom: 6px;
      }
    }
  }
}
