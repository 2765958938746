//Desktop Repair Modal Styles
.upload-quote-modal {
    .modal-dialog {
        justify-content: center;
        max-width: 600px;
        font-size: 14px;

        .modal-content {
            border: 0;
            border-radius: 0;

            .modal-header {
                background-color: #eee;
                align-items: center;
                padding: 15px 28px;

                @media screen and (max-width: 575px) {
                    padding: 15px 20px;
                }

                .md-modal-title {
                    font-family: "Mark", sans-serif;
                    font-weight: 700;
                    font-size: 23px;

                    @media screen and (max-width: 575px) {
                        font-size: 16px;
                    }

                    .fa-print {
                        margin-left: 10px;
                    }
                }
            }

            .modal-body {
                max-height: 680px;
                overflow-y: scroll;
                padding: 30px;

                @media screen and (max-width: 575px) {
                    max-height: 100%;
                }

                .md-modal-subheader {
                    font-size: 16px;
                    letter-spacing: normal;
                    margin: 0;
                    max-width: 430px;
                    padding: 0px 0px 15px 0px;

                    @media screen and (max-width: 575px) {
                        max-width: 180px;
                    }
                }

                .upload-quote-form {
                    display: flex;
                    flex-direction: column;
                    
                    label {
                        align-self: flex-start;
                        color: #009FD9;
                        font-family: "Mark", sans-serif;
                        font-weight: 700;
                        margin: 10px 0 0;

                        &:first-child {
                            margin: 0;
                        }
                    }

                    input {
                        border: 1px solid #dddddd;
                        border-radius: 4px;
                        padding: 10px;
                    }

                    .quote-details {
                        display: flex;
                        flex-direction: column;

                        p {
                            margin: 0;
                        }
                    }
                }

                .md-modal-actions {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    margin-top: 10px;

                    @media (max-width: 575px) {
                        flex-direction: column;
                    }

                    .btn {
                        border-radius: 3px;
                        font-size: 14px;
                        font-weight: 700;
                        padding: 8px 15px;
                        width: calc(50% - 10px);
                        margin: 5px;

                        @media (max-width: 575px) {
                            width: 100%;
                        }

                        &.disabled {
                            background: #979797;
                            border: 1px solid #979797;
                            opacity: 0.5;
                            cursor: default;
                        }
                    }

                    .cancel-btn {
                        border: 1px solid #2F3033;
                        border-radius: 2px;
                    }

                    .submit-btn {
                        background-color: #009FD9;
                        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
                        color: #ffffff;

                        @media (max-width: 575px) {
                            margin-bottom: 30px;
                        }
                    }
                }

            }
        }
    }
}