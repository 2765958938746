.App {
  height: 100vh;
  width: 100vw;
}

@media screen and (max-width: 1200px) {
  body {
    font-size: 17px;
  }
}

@media screen and (max-width: 920px) {
  body {
    font-size: 16px;
  }
}
