.main-layout-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 0;
  max-width: none !important;

  @media print {
    margin: 0 !important;
    max-width: 1150px !important;

    #main {
      width: 100%;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 0;

    .report-wrapper {
      padding: 0;
    }
  }

  .md-root-footer {
    padding: 60px 20px !important;
  }

  .menu-link-container {
    position: absolute;
    top: 20px;
    left: 18px;
    z-index: 100;

    .menu-link {
      color: #009FD9;

      @media print {
        display: none !important;
      }
    }
  }

  .md-header-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;

    &.start {
      align-items: flex-start;
    }

    &.center {
      align-items: center;
    }

    .md-header {
      font-family: "Mark";
      font-weight: 700;
      text-align: center;
      margin-bottom: 0;
      font-size: 53px;
      color: #009FD9;
      letter-spacing: -2px;

      @media screen and (max-width: 575px) {
        font-size: 35px;
        text-align: left;
      }
    }

    .md-subheader {
      font-family: "Mark";
      font-size: 14px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #63666a;
      text-align: center;

      @media screen and (max-width: 575px) {
        text-align: left;
      }
    }
  }
}

.md-contact-modal {
  .modal-dialog {
    max-width: 600px;

    .modal-content {
      .modal-body {
        .file-drop {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          border: dashed 2px rgba(10, 55, 130, 0.25);
          padding: 20px;
          width: 100%;
          height: 150px;

          p {
            display: flex;
            align-items: center;
            margin: 0;

            i {
              margin-right: 10px;
            }

            a {
              margin-left: 4px;
            }
          }
        }

        .btn {
          float: right;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-weight: bold;
          letter-spacing: normal;
          margin-top: 10px;
          min-width: 115px;
          border: 0;
          border-radius: 0;
          height: 50px;
          margin-left: 10px;
          width: 150px;

          clip-path: polygon(100% 0%, 100% 100%, 17px 100%, 0% 65%, 0% 0%);
        }

        label {
          margin-top: 10px;
          font-family: "Mark", sans-serif;
          font-size: 12px;
          font-weight: 700;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #63666a;
          text-transform: uppercase;
          width: 100%;
        }

        input[type="text"] {
          font-size: 16px;
          width: 100%;
          height: 50px;
          border: 1px solid #dde3e8;
          padding: 15px;
        }

        textarea {
          font-size: 16px;
          padding: 15px;
          width: 100%;
          border: 1px solid #dde3e8;
        }
      }
    }
  }
}

.alert {
  font-size: 14px;
}

.footer {
  //padding: 100px 150px 100px 150px !important;
  max-width: 1400px;
  margin: auto;
}
