.md-cost-input {
    font-family: "Mark", sans-serif;
    position: relative;

    &.invalid {
        color: #c42527;

        input {
            border: 1px solid #c42527;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input {
        border: 1px solid #dddddd;
        border-radius: 4px;
        padding: 10px 31px 10px 20px !important;
        text-align: right;
        width: 100%;

        -moz-appearance: textfield;
        margin: 0;
    }

    span {
        font-weight: 500;
        position: absolute;
        top: 8px;
    }

    #dollarSign {
        left: 7px;
    }

    #decimal {
        right: 6px;
    }
}