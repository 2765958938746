.md-confirm-modal {

    .modal-dialog {
        justify-content: center;
        max-width: 600px !important;
        font-size: 14px;
        margin-left: auto;
        margin-right: auto;

        .modal-content {
            border: 0;
            border-radius: 0;
        }

        .modal-header {
            h4 {
                margin: 0;
            }
        }

        .modal-body {
            align-items: center;
            display: flex;
            flex-direction: column;
            padding: 20px;

            p {
                font-size: 14px;
                margin-bottom: 20px;
            }

            .md-confirm-actions {
                width: 100%;

                @media (max-width: 575px) {
                    flex-direction: column;
                }

                .btn {
                    border-radius: 3px;
                    font-size: 14px;
                    font-weight: 700;
                    padding: 8px 15px;
                    width: calc(50% - 10px);
                    margin: 5px;
                    text-transform: capitalize;

                    @media (max-width: 575px) {
                        width: 100%;
                        margin-bottom: 5px;
                    }
                }
    
                .btn-delete {
                    background-color: #c42527;
                    color: #ffffff;
                }
    
                .btn-cancel {
                    background-color: #ffffff;
                    border: 1px solid #2F3033;
                    color: #2F3033;
    
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
        }
    }

    .react-pdf__Page__canvas {
        width: 100% !important;
        height: 100% !important;
    }
}