.md-user-repair-table {
    font-family: "Mark", sans-serif;

    .row {
        flex: 1 0 100%;
    }

    h4 {
        font-size: 18px;
        margin: 0;
    }

    p {
        font-size: 14px;
        margin: 0;
    }

    input[type="checkbox"] {
        -webkit-appearance: none;
        width: 15px;
        height: 15px;
        background: transparent;
        border-radius: 2px;
        border: 2px solid #bfc1c3;
        margin: 0 5px 0 0;
    
        @media screen and (max-width: 575px) {
            width: 20px;
            height: 20px;
            margin-left: 5px;
        }

        &:checked {
            background: white;
            border: 5px solid #009FD9;
        
            @media screen and (max-width: 575px) {
                border: 7px solid #009FD9;
            }
        }

        &:focus {
            background: transparent;
            outline: 5px solid transparent;
        }
    }


    select {
        padding: 10px;
        border: 1px solid #cccccc;
        border-radius: 2px;

        &.default {
            color: #777777;
        }
    }

    .table-header-wrapper {
        padding: 0 30px; 
        margin-bottom: 10px;

        @media (max-width: 575px) {
            padding: 0 5px 0 30px;
        }

        .table-header {
            margin-right: 0;
            border-bottom: 1px solid #eeeeee;

            .table-actions {
                @media (max-width: 575px) {
                    padding: 0 10px;
                }

                .col-12 {
                    display: flex;
                }

                .table-action {
                    display: flex;
                    align-items: center;

                    .action-wrapper {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .global-actions {
                            display: flex;
                            align-items: center;

                            @media (max-width: 575px) {
                                select {
                                    max-width: 120px;
                                }
                            }
                        }   

                        .mobile-filter {
                            display: flex;
                            align-items: center;

                            select {
                                border: 0;
                                max-width: 120px;
                            }
                        }
                    }

                    input[type="checkbox"] {
                        margin-right: 15px;
                    }
                }
            }

            .table-filters {
                display: flex;
                align-items: center;

                .table-filter {
                    p {
                        text-align: center;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
            }
        }
    }

    .table-body {

        .section-header {
            background-color: #009FD9;
            color: #ffffff;

            .section-title {
                display: flex;
                align-items: center;
                padding: 15px 30px;

                @media screen and (max-width: 575px) {
                    padding: 15px;
                }

                .subtitle {
                    font-size: 14px;
                    font-weight: normal;
                }
            }

            .section-details-wrapper {
                display: flex;
                align-items: center;

                .section-details {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    white-space: nowrap;

                    @media screen and (max-width: 575px) {
                        justify-content: flex-end;
                    }
                }
            }
        }
    }

    .no-requests-message {
        padding: 0px 30px 10px;
    }

    .additional-request-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0px;
        
        .btn {
            border-radius: 2px;
            font-family: "Mark", sans-serif;
            font-size: 14px;
            font-weight: 700;
            margin: 2px 0;
            width: 217px;
        }

        .add-new-btn {
            background: #ffffff;
            border: 1px solid #63666a;
            color: #2F3033 !important;

            &:hover {
                border: 1px solid #2F3033;
                color: #2F3033 !important;
            }
        }
    }
}