.md-repair-table {
  font-family: "Mark", sans-serif;

  .row {
    flex: 1 0 100%;
  }

  h4 {
    font-size: 18px;
    margin: 0;
  }

  p {
    font-size: 14px;
    margin: 0;
  }

  input[type="checkbox"] {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    background: transparent;
    border-radius: 2px;
    border: 2px solid #bfc1c3;
    margin: 0 5px 0 0;

    @media screen and (max-width: 575px) {
      width: 20px;
      height: 20px;
      margin-left: 5px;
    }

    &:checked {
      background: white;
      border: 5px solid #009FD9;

      @media screen and (max-width: 575px) {
        border: 7px solid #009FD9;
      }
    }

    &:focus {
      background: transparent;
      outline: 5px solid transparent;
    }
  }

  select {
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 2px;

    &.default {
      color: #777777;
    }
  }

  .table-header-wrapper {
    padding: 0 30px;
    margin-bottom: 10px;

    @media (max-width: 575px) {
      padding: 0 5px 0 30px;
    }

    .table-header {
      margin-right: 0;

      .table-actions {
        @media (max-width: 575px) {
          padding: 0 10px;
        }

        .col-12 {
          display: flex;
        }

        .table-action {
          display: flex;
          align-items: center;

          p {
            color: #009fd9;

            &:hover {
              cursor: pointer;
              text-decoration: none;
            }
          }

          .action-wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .global-actions {
              display: flex;
              align-items: center;

              @media (max-width: 575px) {
                select {
                  max-width: 120px;
                }
              }
            }

            .mobile-filter {
              display: flex;
              align-items: center;

              select {
                border: 0;
                max-width: 120px;
              }
            }
          }
        }
      }

      .table-filters {
        display: flex;
        align-items: center;

        .table-filter {
          p {
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            color: #676d73;

            &:hover {
              cursor: pointer;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .table-body {
    // padding: 0;

    .section-header {
      background-color: #009fd9;
      color: #ffffff;
      margin: 0 -15px;
      width: auto;
      break-inside: avoid !important;

      .section-title {
        display: flex;
        align-items: center;
        padding: 15px 30px;

        @media screen and (max-width: 575px) {
          padding: 15px;
        }

        .subtitle {
          font-size: 14px;
          font-weight: normal;
        }
      }

      .section-details-wrapper {
        display: flex;
        align-items: center;

        .section-details {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          white-space: nowrap;

          @media screen and (max-width: 575px) {
            align-items: flex-end;
            flex-direction: column;
            justify-content: flex-end;
          }

          .md-discounted-price {
            color: #aaaaaa;
            font-weight: normal;
            position: relative;

            @media screen and (max-width: 575px) {
              font-size: 14px;
            }

            &:before {
              position: absolute;
              content: "";
              left: 0;
              top: 50%;
              right: 0;
              border-top: 1px solid;
              border-color: inherit;

              -webkit-transform: rotate(-5deg);
              -moz-transform: rotate(-5deg);
              -ms-transform: rotate(-5deg);
              -o-transform: rotate(-5deg);
              transform: rotate(-5deg);
            }
          }

          h4 {
            align-items: center;
            display: flex;

            i {
              cursor: pointer;
              font-size: 14px;
              font-weight: normal;
            }
          }

          .combined-cost-estimate {
            position: relative;

            .combined-estimate-info {
              background: #ffffff;
              border: 1px solid #cccccc;
              right: -145px;
              padding: 10px;
              position: absolute;
              top: 25px;
              width: 300px;
              z-index: 100;

              @media (max-width: 575px) {
                right: -6px;
                width: 250px;
              }

              &::after {
                content: "";
                position: absolute;
                bottom: 100%;
                left: 50%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: transparent transparent #ffffff transparent;

                @media (max-width: 575px) {
                  left: calc(100% - 10px);
                }
              }

              p {
                color: #2F3033;
                white-space: normal;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
