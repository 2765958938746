.md-domoreport-page {
    &.pending {
        @media screen and (max-width: 575px) {
            margin: 70px 15px;
        }

        .md-placeholder-comments {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "Mark", sans-serif;
            font-size: 20px;
            font-weight: 700;
            padding: 20px;
            text-align: center;

            @media screen and (max-width: 575px) {
                font-size: 16px;
                }

            a:hover {
                    color: #009FD9 !important
                }
        }

        .md-placeholder-content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            img {
                margin: 40px 0;
                box-shadow: 0px 8px 20px 0 rgba(0, 55, 130, 0.18);
            }
        }
    }
}