//Desktop Repair Modal Styles
.md-domoreport-modal {
  .modal-dialog {
    justify-content: center;
    max-width: 700px !important;
    font-size: 14px;

    .modal-content {
      border: 0;
      border-radius: 0;

      .modal-header {
        background-color: #eee;
        align-items: center;
        padding: 15px 28px;

        @media screen and (max-width: 575px) {
          padding: 15px 20px;
        }

        .md-domoreport-modal-title {
          font-family: "Mark", sans-serif;
          font-weight: 700;
          font-size: 23px;

          @media screen and (max-width: 575px) {
            font-size: 16px;
            max-width: 50%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .fa-print {
            margin-left: 10px;
          }
        }
      }

      .modal-body {
        // max-height: 680px;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 30px;

        // @media screen and (max-width: 575px) {
        //   max-height: 100%;
        // }

        select:disabled {
          background: #f8f8f8;
          cursor: no-drop;
        }

        textarea:disabled {
          opacity: 0.5;
          cursor: no-drop;
        }

        .md-domoreport-modal-subheader {
          font-size: 16px;
          letter-spacing: normal;
          margin: 0;
          max-width: 430px;
          padding: 0px 0px 15px 0px;

          @media screen and (max-width: 575px) {
            max-width: 180px;
          }
        }

        .md-domoreport-modal-details {
          padding: 0px 0px;

          p {
            white-space: pre-line;
          }

          .md-priority-tag {
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            margin-left: auto;
            margin-right: auto;
            border: 0;
            width: 100%;
            padding: 5px;
            clip-path: polygon(100% 0, 100% 100%, 0% 100%, 5% 50%, 0 0);
            font-family: "Mark", sans-serif;
            font-size: 12px;
            font-weight: 700;
            max-width: 120px;
            float: right;
            position: absolute;
            right: 0;
            top: 26px;

            @media screen and (max-width: 575px) {
              top: 20px;
              max-width: 100px;
            }
          }
        }

        .pros-modal-link {
          font-weight: 700;
          font-size: 14px;
          background: #009FD9;
          color: #ffffff;
        }

        .request-list-wrapper {
          display: flex;
          flex-direction: column;
          position: relative;

          .repair-list-header {
            background-color: #FAFAFA;
            display: flex;
            align-items: center;
            margin: 10px 0px 0px;
            padding: 15px 15px;

            .md-domoreport-modal-subheader {
              color: #009FD9;
              padding: 0;
            }
          }

          .request-list-actions {
            display: flex;
            flex-direction: column;
            padding: 0 5px;
            position: relative;

            label {
              color: #009FD9;
              font-weight: 600;
              margin: 10px 0 0;

              &.invalid {
                color: #c42527;
              }
            }

            .row {
              @media (max-width: 575px) {
                margin: 0;
              }
            }

            .request-value-wrapper {
              align-items: center;
              display: flex;
              margin: 0;
              position: relative;

              input {
                border: 1px solid #dddddd;
                border-radius: 4px;
                margin: 0;
                padding: 9px 10px 9px 15px;
                width: 100%;
              }

              input[type="file"] {
                padding: 7px;
              }

              p {
                margin: 0;
                position: absolute;
                top: 10px;
                left: 5px;
              }

              .uploaded-quote-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                p {
                  left: auto;
                  margin: 0;
                  overflow: hidden;
                  position: relative;
                  text-overflow: ellipsis;
                  top: auto;
                  white-space: nowrap;
                }
              }
            }

            .request-notes-wrapper {
              display: flex;
              flex-direction: column;
              padding: 0;

              @media (max-width: 575px) {
                padding: 0 15px;
              }
            }

            select {
              border: 1px solid #dddddd;
              border-radius: 4px;
              margin: 0;
              padding: 10px;
              width: 100%;

              &.default {
                color: #777777;
              }
            }

            textarea {
              border: 1px solid #dddddd;
              border-radius: 4px;
              margin: 0;
              min-height: 100px;
              padding: 8px;
            }
          }
        }

        hr {
          margin-top: 20px;
          margin-bottom: 20px;
        }

        .react-pdf__Page__canvas {
          width: 100% !important;
          height: 100% !important;

          .react-pdf__Page__textContent {
            width: 600px;
            height: 690px;
          }
        }

        .md-domoreport-modal-actions {
          align-items: center;
          border-top: 1px solid #eeeeee;
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-top: 20px;
          padding-top: 10px;

          @media (max-width: 575px) {
            flex-direction: column;
          }

          .btn {
            color: #2F3033 !important;
            border-radius: 3px;
            font-size: 14px;
            font-weight: 700;
            padding: 8px 15px;
            width: calc(50% - 10px);
            margin: 5px;

            @media (max-width: 575px) {
              width: calc(100% - 40px);
            }
          }

          .request-change-btn {
            border: 1px solid #2F3033;
            border-radius: 2px;
          }

          .toggle-btn {
            background-color: #ffffff;
            border: 1px solid #2F3033;
            color: #2F3033;

            @media (max-width: 575px) {
              margin-bottom: 30px;
            }

            &:focus {
              outline: none;
              box-shadow: none;
            }
          }
        }

        .modal-table {
          width: 100%;

          .table-heading {
            color: #272525;
            font-size: 18px;
            font-weight: 600;
            padding: 12px 15px 12px 8px;

            span {
              text-decoration: none dashed;
              color: #2174a5;
            }

            .md-estimate-info {
              display: flex;
              position: absolute;
              background: white;
              max-width: 280px;
              border: 1px solid #333;
              padding: 2px;
              font-size: 10px;
              font-weight: normal;

              @media screen and (max-width: 575px) {
                max-width: 180px;
              }
            }
          }

          tr {
            padding: 10px 15px;
            display: inline-flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            td {
              font-size: 14px;
              padding: 0;
            }
          }

          .md-table-light {
            color: #52575a;
            background-color: #f6f7f7;
          }

          .md-table-dark {
            color: #52575a;
            background-color: #FAFAFA;
          }

          .table-footer {
            padding: 12px 15px 12px 8px;
          }
        }
      }
    }

    .inspection-page {
      display: none;
      -webkit-transition: 0.4s;
      -moz-transition: 0.4s;
      -o-transition: 0.4s;
      transition: 0.4s;
    }

    .inspection-page.show {
      display: inline;
    }
  }
}

//Mobile Repair Modal Styles
@media screen and (max-width: 575px) {
  .md-domoreport-modal {
    .modal-content {
      height: 100vh;
      overflow: scroll;
      border-radius: 0 !important;

      .modal-header {
        padding: 20px;
        border-radius: 0;

        .md-domoreport-modal-header {
          font-size: 20px;
        }
      }

      .modal-body {
        padding: 0 !important;
        border-radius: 0;

        .md-domoreport-modal-details {
          padding: 20px 20px 0px 20px !important;
        }

        .react-pdf__Page__textContent {
          height: 400px !important;
          width: 290px !important;
          overflow: hidden;
        }

        .modal-table {
          td {
            padding: 3px 20px !important;
          }
        }
      }
    }

    .modal-dialog-centered {
      margin: 0;
    }
  }
}
