.display-options-info-modal {
    font-size: 14px;

    h4 {
        margin: 0;
    }

    .display-options-info {
        hr {
            margin: 5px 5px 15px;
        }

        .display-options-title {
            font-size: 16px;
            font-weight: 500;
            margin: 0;
            text-transform: uppercase;
        }

        .display-option {
            border-bottom: 1px solid #eeeeee;
            margin-bottom: 25px;
            padding-bottom: 15px;

            img {
                background-color: #eeeeee;
                border: 1px solid #dddddd;
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
                box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.04);
                margin-bottom: 10px;
                padding: 5px;
            }

            h4 {
                font-size: 14px;
            }

            p {
                margin-bottom: 5px;
            }
        }
    }
}