.md-printable-repair {
    max-width: 1100px;
    margin: 0 auto;
    border: 1px solid #eeeeee;
    font-size: 16px;

    @media print {
        margin: 0 25px;
        width: 1125px;
        max-height: 1400px;
        border: 0;
    }

    .print-break {
        page-break-after: always;
    }

    .md-printable-title {
        font-size: 23px;
        padding: 15px;
        background-color: #eeeeee;
    }

    .md-printable-details {
        padding: 15px;
        margin: 0 auto;

        .md-printable-subheader-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 10px;

            .md-printable-subheader {
                padding-bottom: 0;
            }

            .md-priority-tag {
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;
                border: 0;
                width: 100%;
                padding: 3px;
                clip-path: polygon(100% 0, 100% 100%, 0% 100%, 5% 50%, 0 0);
                font-size: 12px;
                max-width: 120px;
                margin-right: -15px;
        
                @media screen and (max-width: 575px) {
                    max-width: 100px;
                }
            }
        }

        .md-printable-subheader {
            font-size: 14px;
            text-transform: uppercase;
            margin: 0;
            max-width: 430px;
            padding-bottom: 10px;
    
            @media screen and (max-width: 575px) {
                max-width: 180px;
            }
        }
    }

    .md-printable-disclaimer {
        border: 1px solid #333;
        padding: 15px;
        margin: 50px 80px;
        font-size: 14px;

        @media screen and (max-width: 991px) {
            margin: 50px 40px;
        }

        @media print {
            margin: 50px 10px;
        }
    }
    
    hr {
        width: 90%;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    .react-pdf__Page__canvas {
        width: 100% !important;
        height: 100% !important;

        @media print {
            page-break-after: always;
            max-height: 1400px;
        }
    
        .react-pdf__Page__textContent {
            width: 600px;
            height: 690px;
        }
    }
    
    .md-printable-table {
        margin: 0 auto;
    
        tr {
            padding: 8px;
            display: inline-flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
    
            td {
                font-size: 14px;
                padding: 0;
            }
        }
    
        .table-heading {
            font-size: 18px;
            font-weight: 600;
            color: #272525;
    
            span {
                text-decoration: none dashed;
                color: #2174a5;
            }
    
            .md-estimate-info {
                display: flex;
                position: absolute;
                background: white;
                max-width: 280px;
                border: 1px solid #333;
                padding: 2px;
                font-size: 10px;
                font-weight: normal;
    
                @media screen and (max-width: 575px) {
                    max-width: 180px;
                }
            }
        }
    
        .md-table-light {
            color: #52575a;
            background-color: #f6f7f7;
        }
    
        .md-table-dark {
            color: #52575a;
            background-color: #FAFAFA;
        }
    }
}