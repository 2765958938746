.request-list-print-options {
  &.sharing {
    .subheader-title {
      h4 {
        display: flex;
        align-items: center;

        .find-pros-link {
          border-radius: 2px;
          font-size: 12px;
          font-weight: 700;
          padding: 5px 15px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          color: #ffffff;
          background: #009FD9;
          box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);

          @media print {
            display: none;
          }

          i {
            margin-left: 5px;
          }
        }
      }
    }

    .print-sidebar {
      font-size: 16px;
      z-index: 100;

      @media (max-width: 575px) {
        font-size: 14px;
      }

      .sidebar-content {
        .sidebar-body {
          max-height: none;
          height: 100vh;

          @media screen and (max-width: 991px) {
            max-height: calc(100vh - 85px);
            padding: 20px 20px 80px;
          }
        }
      }
    }

    .md-shared-request-list {
      background: #dddddd;
      display: flex;
    }
  }
}
